export const ACTIVATE_SHARE_MODAL = 'ACTIVATE_SHARE_MODAL';
export const CLOSE_SHARE_MODAL = 'CLOSE_SHARE_MODAL';

export const activateShareModal = (payload) => ({
  type: ACTIVATE_SHARE_MODAL,
  payload,
});

export const closeShareModal = () => ({
  type: CLOSE_SHARE_MODAL,
});

const initialState = {
  content: {},
  isModalOpen: false,
  strategy: '',
};

const sharingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_SHARE_MODAL:
      return {
        ...state,
        content: action.payload.content,
        isModalOpen: true,
        strategy: action.payload.strategy,
      };
    case CLOSE_SHARE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default sharingReducer;
