import { useState } from 'react';
import useForm from './useForm';

const useToast = (duration = 1500) => {
  const { formValues, updateFormFields } = useForm({
    sentiment: 'neutral',
    message: '',
  });
  const [shouldShowToast, setShouldShowToast] = useState(false);

  const triggerToast = ({ sentiment = 'neutral', message = '' }) => {
    updateFormFields({ sentiment, message, shouldShowToast });
    setShouldShowToast(true);
    setTimeout(() => {
      setShouldShowToast(false);
    }, duration);
  };

  const {
    message: toastMessage,
    sentiment: toastSentiment,
  } = formValues;

  return {
    shouldShowToast,
    triggerToast,
    toastDuration: duration,
    toastMessage,
    toastSentiment,
  };
};

export default useToast;
