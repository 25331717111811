import React from 'react';
import PropTypes from 'prop-types';

const ArrowCounterClockwise = ({ size }) => (
  <svg className="bi bi-flag-fill" width={size} height={size} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z" />
    <path fillRule="evenodd" d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z" />
  </svg>
);

ArrowCounterClockwise.displayName = 'ArrowCounterClockwise';

ArrowCounterClockwise.propTypes = {
  size: PropTypes.string.isRequired,
};

export default ArrowCounterClockwise;
