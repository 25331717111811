export default class MetaMessageBuilder {
  constructor({ count, filters, formatMessage }) {
    this.count = count;
    this.filters = filters;
    this.formatMessage = formatMessage;

    // default message parts
    this.administrativeArea1 = '';
    this.administrativeArea2 = '';
    this.allFellowshipsModifier = '';
    this.countMessage = '';
    this.country = '';
    this.dayOfWeekSegment = '';
    this.fellowshipSegment = '';
    this.locality = '';
    this.meetingWord = '';
    this.tagSegment = '';
    this.timeOfDaySegment = '';

    this.locationSegment = '';
  }

  calculateLocationSegment() {
    const locatedInMessage = this.formatMessage({ id: 'resultsMeta-locatedIn' });

    const shouldShowLocatedInMessage = [
      this.locality,
      this.administrativeArea1,
      this.administrativeArea2,
      this.country,
    ].some((field) => !!field);

    const assembledLocationFields = `${this.locality}${this.administrativeArea1}${this.administrativeArea2}${this.country}`;

    if (shouldShowLocatedInMessage) {
      this.locationSegment = `, ${locatedInMessage} ${assembledLocationFields}`;
    }

    return this.locationSegment;
  }

  calculateCountMessage() {
    this.countMessage = this.formatMessage(
      { id: 'resultsMeta-count' },
      { count: this.count }
    );

    return this.countMessage;
  }

  calculateMeetingWord() {
    this.meetingWord = this.formatMessage(
      { id: 'resultsMeta-meeting' },
      { count: this.count }
    );

    return this.meetingWord;
  }

  calculateDayOfWeekSegment() {
    if (this.filters.dayOfWeek !== null) {
      const dayOfWeekByName = this.formatMessage({
        id: `day-${this.filters.dayOfWeek}-name`,
      });

      const dayOfWeekMessage = this.formatMessage({
        id: 'resultsMeta-dayOfWeek',
      }, {
        dayOfWeek: dayOfWeekByName,
      });

      this.dayOfWeekSegment = ` ${dayOfWeekMessage}`;
    }

    return this.dayOfWeekSegment;
  }

  calculateTimeOfDaySegment() {
    if (this.filters.timeOfDay) {
      const timeOfDayByName = this.formatMessage({
        id: `timeOfDay-${this.filters.timeOfDay}`,
      }).toLowerCase();

      const timeOfDayMessage = this.formatMessage({
        id: 'resultsMeta-timeOfDay',
      }, {
        timeOfDay: timeOfDayByName,
      });

      this.timeOfDaySegment = ` ${timeOfDayMessage} `;
    }

    return this.timeOfDaySegment;
  }

  calculateTagSegment() {
    if (this.filters.tags && this.filters.fellowship !== 'all') {
      const tagType = this.formatMessage({
        id: `meetingTag-${this.filters.fellowship}-${this.filters.tags}`,
      });

      this.tagSegment = ` ${tagType}`;
    }

    return this.tagSegment;
  }

  calculateFellowshipSegment() {
    this.fellowshipSegment = this.formatMessage({
      id: `fellowshipShortName-${this.filters.fellowship}`,
    });

    return this.filters.fellowship === 'all' ? '' : ` ${this.fellowshipSegment}`;
  }

  calculateAllFellowshipsModifier() {
    if (this.filters.fellowship === 'all') {
      const allFellowshipsModifierMessage = this.formatMessage({
        id: 'fellowshipTitle-all',
      });

      this.allFellowshipsModifier = ` ${allFellowshipsModifierMessage}`;
    }

    return this.allFellowshipsModifier;
  }

  calculateCountry() {
    if (this.filters.country) {
      this.country = this.filters.country;
    }

    return this.country;
  }

  calculateAdministrativeArea1() {
    if (this.filters.administrativeArea1) {
      this.administrativeArea1 = `${this.filters.administrativeArea1}, `;
    }

    return this.administrativeArea1;
  }

  calculateAdministrativeArea2() {
    if (this.filters.administrativeArea2) {
      this.administrativeArea2 = `${this.filters.administrativeArea2}, `;
    }

    return this.administrativeArea2;
  }

  calculateLocality() {
    if (this.filters.locality) {
      this.locality = `${this.filters.locality}, `;
    }

    return this.locality;
  }

  execute() {
    this.processFields();
    return this.assembleMessage();
  }

  assembleMessage() {
    const preMeetingSegment = `${this.countMessage}${this.timeOfDaySegment}${this.tagSegment}${this.filters.fellowship !== 'all' ? this.fellowshipSegment : ''}`;
    const postMeetingSegment = `${this.dayOfWeekSegment}${this.allFellowshipsModifier}${this.locationSegment}`;

    return `${preMeetingSegment} ${this.meetingWord} ${postMeetingSegment}`;
  }

  processFields() {
    this.calculateCountMessage();
    this.calculateMeetingWord();
    this.calculateDayOfWeekSegment();
    this.calculateTimeOfDaySegment();
    this.calculateTagSegment();
    this.calculateFellowshipSegment();
    this.calculateAllFellowshipsModifier();
    this.calculateCountry();
    this.calculateAdministrativeArea1();
    this.calculateAdministrativeArea2();
    this.calculateLocality();
    this.calculateLocationSegment();
  }
}
