import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import { pickBy } from 'lodash';
import { connectAsync } from 'iguazu';
import { queryResource } from 'iguazu-rest';
import Filters from './Filters';

const MeetingFiltersSideWidget = () => {
  const [isDefaultStickyPosition, setIsDefaultStickyPosition] = useState(true);
  const handleStickyStateChange = ({ status }) => {
    if (status === 0) { return setIsDefaultStickyPosition(true); }
    return setIsDefaultStickyPosition(false);
  };

  return (
    <Sticky enabled={true} top="#navbar" onStateChange={handleStickyStateChange}>
      <div className={`card ${isDefaultStickyPosition ? '' : 'mt-4'}`}>
        <div className="card-body">
          <Filters selectClassName="select-sm" />
        </div>
      </div>
    </Sticky>
  );
};

MeetingFiltersSideWidget.displayName = 'MeetingFiltersSideWidget';

MeetingFiltersSideWidget.propTypes = {
  meetingMeta: PropTypes.shape({
    count: PropTypes.number,
  }),
};

MeetingFiltersSideWidget.defaultProps = {
  meetingMeta: {},
};

export const loadDataAsProps = ({ store: { dispatch, getState } }) => {
  const { filters: { activeFilters } } = getState();
  const validFilters = pickBy(activeFilters, (item) => !!item || item === 0);

  return {
    meetingMeta: () => dispatch(queryResource({
      resource: 'meetingMeta',
      id: validFilters,
      opts: {
        query: validFilters,
      },
    })),
  };
};

export default connectAsync({ loadDataAsProps })(MeetingFiltersSideWidget);
