import React from 'react';
import PropTypes from 'prop-types';
import Toast from '../../lib/Toast';

const MeetingItemToast = ({
  shouldShowToast,
  toastSentiment,
  toastDuration,
  toastMessage,
}) => shouldShowToast && (
  <div className="row">
    <div className="col-md-12">
      <Toast sentiment={toastSentiment} duration={toastDuration}>
        <p className="text-center">
          <em>{toastMessage}</em>
        </p>
      </Toast>
    </div>
  </div>
);

MeetingItemToast.displayName = 'MeetingItemToast';

MeetingItemToast.propTypes = {
  toastSentiment: PropTypes.string.isRequired,
  toastDuration: PropTypes.number.isRequired,
  toastMessage: PropTypes.string.isRequired,
  shouldShowToast: PropTypes.bool.isRequired,
};

export default MeetingItemToast;
