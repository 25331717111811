import PropTypes from 'prop-types';

const geoShape = PropTypes.shape({
  administrativeArea1: PropTypes.string,
  administrativeArea2: PropTypes.string,
  administrativeArea3: PropTypes.string,
  country: PropTypes.string,
  locality: PropTypes.string,
  neighborhood: PropTypes.string,
  sublocality: PropTypes.string,
});

export default geoShape;
