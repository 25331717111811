export const LOAD_GEO = 'LOAD_GEO';
export const LOAD_GEO_SUCCESS = 'LOAD_GEO_SUCCESS';
export const LOAD_GEO_ERROR = 'LOAD_GEO_ERROR';

export const loadGeoSuccess = (payload) => ({
  type: LOAD_GEO_SUCCESS,
  payload,
});

export const loadGeoError = (error) => ({
  type: LOAD_GEO_ERROR,
  error,
});

export const loadGeo = () => (dispatch) => {
  dispatch({ type: LOAD_GEO });

  fetch('https://steps-video-93623.appspot.com/api/v1/geo')
    .then((res) => res.json())
    .then((data) => dispatch(loadGeoSuccess(data)))
    .catch((err) => dispatch(loadGeoError(err)));
};

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GEO:
      return { ...state, isLoading: true };
    case LOAD_GEO_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };
    case LOAD_GEO_ERROR:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export default reducer;
