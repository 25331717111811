import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Toast = ({
  children,
  duration,
  message,
  sentiment,
}) => {
  const [animationClass, setAnimationClass] = useState('fadeIn');
  const className = `animated ${animationClass} text-${sentiment}`;

  setTimeout(() => setAnimationClass('fadeOut'), duration - 1);

  return (
    <div className={className}>
      {children || <p>{message}</p>}
    </div>
  );
};

Toast.displayName = 'Toast';

Toast.propTypes = {
  children: PropTypes.element.isRequired,
  duration: PropTypes.number.isRequired,
  message: PropTypes.string,
  sentiment: PropTypes.string.isRequired,
};

Toast.defaultProps = {
  message: '',
};

export default Toast;
