/* eslint-disable class-methods-use-this */

import { blacklist, trim } from 'validator';

export default class LocationBuilder {
  constructor(geo) {
    const blacklistCharacters = ['-'];

    this.administrativeArea1 = trim(blacklist(this.sanitizeForUndefinedValue(geo.administrativeArea1), blacklistCharacters) || '');
    this.administrativeArea2 = trim(blacklist(this.sanitizeForUndefinedValue(geo.administrativeArea2), blacklistCharacters) || '');
    this.administrativeArea3 = trim(blacklist(this.sanitizeForUndefinedValue(geo.administrativeArea3), blacklistCharacters) || '');
    this.country = trim(blacklist(this.sanitizeForUndefinedValue(geo.country), blacklistCharacters) || '');
    this.locality = trim(blacklist(this.sanitizeForUndefinedValue(geo.locality), blacklistCharacters) || '');
    this.sublocality = trim(blacklist(this.sanitizeForUndefinedValue(geo.sublocality), blacklistCharacters) || '');
    this.neighborhood = trim(blacklist(this.sanitizeForUndefinedValue(geo.neighborhood), blacklistCharacters) || '');
  }

  sanitizeForUndefinedValue(value) {
    return value || '';
  }

  sanitizeForEmptyValue(value) {
    return value === ' ' ? '' : value;
  }

  build() {
    const countyOrBorough = this.sublocality || this.administrativeArea2;

    return {
      locationLine1: this.parseLocationLine1(
        this.sanitizeForEmptyValue(this.locality),
        this.sanitizeForEmptyValue(this.administrativeArea1),
        this.sanitizeForEmptyValue(this.country)
      ),
      locationLine2: this.parseLocationLine2(
        this.sanitizeForEmptyValue(countyOrBorough),
        this.sanitizeForEmptyValue(this.administrativeArea3),
        this.sanitizeForEmptyValue(this.neighborhood)
      ),
    };
  }

  parseCommaSeparatedValues(value1, value2) {
    if (value1 && value2) { return `${value1}, ${value2}`; }
    if (value1 || value2) { return `${value1 || value2}`; }
    return '';
  }

  parseLocationLine1(locality, administrativeArea1, country) {
    const commaSeparatedValues = this
      .parseCommaSeparatedValues(locality, administrativeArea1);

    if (!commaSeparatedValues) { return country || ''; }

    return country && country !== 'United States'
      ? `${commaSeparatedValues} (${country})`
      : commaSeparatedValues;
  }

  parseLocationLine2(administrativeArea2, administrativeArea3, neighborhood) {
    const commaSeparatedValues = this
      .parseCommaSeparatedValues(administrativeArea2, administrativeArea3);

    if (!commaSeparatedValues) { return neighborhood || ''; }

    return neighborhood
      ? `${commaSeparatedValues} - ${neighborhood}`
      : commaSeparatedValues;
  }
}
