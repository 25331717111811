import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

// export const FormattedMessage = ({ id }) => <span>{langPack[id] || id}</span>;

// FormattedMessage.propTypes = { id: PropTypes.string };
// FormattedMessage.defaultProps = { id: PropTypes.string };

// export const useIntl = () => ({
//   formatMessage: ({ id }) => langPack[id] || id,
//   locale: 'en',
// });


export const FormattedMessage = (props) => <Trans i18nKey={props.id} {...props}>{props.id}</Trans>;
FormattedMessage.propTypes = { id: PropTypes.string.isRequired };

export const useIntl = () => {
  const { t } = useTranslation();

  return {
    formatMessage: ({ id }, rest) => t(id, rest),
    locale: t('locale'),
  };
};
