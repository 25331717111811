import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from '../ReactIntlAdapter';
import { useMeta } from '../../hooks/useMeta';
import Dropdown from '../Dropdown';
import LocationOptionCalculator from './LocationOptionCalculator';

const LocationDropdown = ({
  className,
  name,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const { sortedGeoData } = useMeta();
  const { activeFilters: filters } = useSelector((state) => state.filters);

  const locationOptionCalculator = new LocationOptionCalculator({
    filters,
    formatMessage,
    sortedGeoData,
  });

  const myOptions = locationOptionCalculator.buildLocationOptions(name);

  return (
    <Dropdown
      buttonClass={`btn-outline-secondary ${className}`}
      label={formatMessage({ id: `dropdownLabel-all-${name}` })}
      name={name}
      onChange={onChange}
      options={myOptions}
    />
  );
};

LocationDropdown.displayName = 'LocationDropdown';

LocationDropdown.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

LocationDropdown.defaultProps = {
  className: '',
};

export default LocationDropdown;
