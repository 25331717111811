import React from 'react';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from 'gatsby';
import { CookiesProvider } from 'react-cookie';
import Styled from '@emotion/styled';
import Navbar from './navigation/Navbar';
import TopLevelNavButtons from './navigation/TopLevelNavButtons';
import InitialDataLayer from './InitialDataLayer';
import FilterModal from './modals/FilterModal';
import GeneralFeedbackModal from './modals/GeneralFeedbackModal';
import NotesModal from './modals/NotesModal';
import ShareModal from './modals/ShareModal';

const MobileNavContainer = Styled.div`
  border-radius: 50px;
  width: 98%;
  margin-left: 1%;
  margin-bottom: .25em;
`;

const Layout = ({ children, mode }) => {
  console.log('layout');

  return (
    <div>
      <InitialDataLayer mode={mode}>
        <CookiesProvider>
          <Navbar siteTitle="12steps.video" />
          <div className="container">
            <main>
              <FilterModal />
              <GeneralFeedbackModal />
              <NotesModal />
              <ShareModal />
              {children}
            </main>
            <footer className="fixed-bottom">
              © {new Date().getFullYear()}
            </footer>
            <MobileNavContainer className="fixed-bottom d-md-none bg-white d-flex justify-content-center align-items-center border mb-2 mb-sm-1">
              <TopLevelNavButtons textClass="text-dark" />
            </MobileNavContainer>
          </div>
        </CookiesProvider>
      </InitialDataLayer>
    </div>
  );
};
// const data = useStaticQuery(graphql`
//   query SiteTitleQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mode: PropTypes.string,
};

Layout.defaultProps = {
  mode: 'default',
};

export default Layout;
