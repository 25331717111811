import React, { useEffect, useRef, useState } from 'react';
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import { FormattedMessage } from './ReactIntlAdapter';

const CopyButton = ({
  className,
  copyText,
}) => {
  const buttonRef = useRef();
  const clipboard = useRef();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!clipboard.current) {
      clipboard.current = new Clipboard(buttonRef.current);
      clipboard.current.on('success', () => setClicked(true));
    }
  }, []);

  useEffect(() => setClicked(false), [copyText]);

  return clicked ? (
    <p className="text-success d-inline-block ml-1 animated fadeIn">
      <em>
        <FormattedMessage id="shareModal-button-copy-confirmed" />
      </em>
    </p>
  ) : (
    <button
      className={`btn ${className}`}
      data-clipboard-text={copyText}
      ref={buttonRef}
      type="button"
    >
      <FormattedMessage id="shareModal-button-copy" />
    </button>
  );
};

CopyButton.propTypes = {
  className: PropTypes.string,
  copyText: PropTypes.string,
};

CopyButton.defaultProps = {
  className: '',
  copyText: '',
};

export default CopyButton;
