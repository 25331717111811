import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useIntl } from '../ReactIntlAdapter';

const LocationInput = ({ handleInput }) => {
  const { formatMessage } = useIntl();

  const [searchQuery, setSearchQuery] = useState('');

  const loadLocationOptions = () => fetch(`https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=RmfjAiMg_ObJhWFYSKi30-uAH-l1-i_L626b4OqIPhA&q=${searchQuery}&at=40.8870025,-74.0459472`)
    .then((res) => res.json())
    .then(({ items: suggestions }) => suggestions
      .map(({ title, position }) => ({ label: title, value: position })));

  const loadReverseGeo = (latitude, longitude) => fetch(`https://revgeocode.search.hereapi.com/v1/revgeocode?apiKey=RmfjAiMg_ObJhWFYSKi30-uAH-l1-i_L626b4OqIPhA&q&at=${latitude},${longitude}`);

  const handleLocationSelection = ({ label, value: { lat, lng } }) => {
    loadReverseGeo(lat, lng)
      .then((res) => res.json())
      .then(({ items }) => {
        const [firstItem] = items;
        const { address } = firstItem;

        const geoPreview = {
          country: address.countryName,
          administrativeArea1: address.state,
          administrativeArea2: address.county,
          locality: address.city,
        };

        handleInput({
          label,
          geo: { lat, lng },
          geoPreview,
        });
      });
    return { label };
  };

  const customStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 1000000,
    }),
  };

  const [target, setTarget] = useState(null);

  useEffect(() => {
    setTarget(document.body);
  }, [setTarget]);

  return (
    <AsyncSelect
      cacheOptions={true}
      isClearable={true}
      loadOptions={loadLocationOptions}
      menuPortalTarget={target}
      name="spelledOutLocation"
      onChange={(newLocationInput) => newLocationInput && handleLocationSelection(newLocationInput)}
      onInputChange={setSearchQuery}
      placeholder={formatMessage({ id: 'addMeeting-inputPlaceholder-spelledOutLocation' })}
      styles={customStyles}
      type="text"
    />
  );
};

LocationInput.displayName = 'LocationInput';

LocationInput.propTypes = {
  handleInput: PropTypes.func.isRequired,
};

export default LocationInput;
