import PropTypes from 'prop-types';

const flagFormShape = PropTypes.shape({
  requestorEmail: PropTypes.string,
  'cantJoin-needPassword': PropTypes.bool,
  'cantJoin-invalidMeetingId': PropTypes.bool,
  'cantJoin-incorrectDialIn': PropTypes.bool,
  'meetingIssue-neverStarted': PropTypes.bool,
  'meetingIssue-wrongTime': PropTypes.bool,
  'changeRequest-submitPassword': PropTypes.string,
  'changeRequest-updateContactEmail': PropTypes.string,
  'changeRequest-updateVideoLink': PropTypes.string,
  notes: PropTypes.string,
});

export default flagFormShape;
