import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import meetingShape from '../../types/meetingShape';
import flagFormShape from '../../types/flagFormShape';
import ChatLeftText from '../../lib/icons/ChatLeftText';
import CircleFill from '../../lib/icons/CircleFill';
import TinyText from '../../lib/TinyText';

const SupportTicketHeader = ({
  ticketType,
  flagForm,
  currentMeetingValues,
}) => {
  const flagColors = {
    newMeeting: 'success',
    changeRequest: 'warning',
    flaggedMeeting: 'danger',
  };

  const flagClassName = `text-${flagColors[ticketType]}`;

  const fieldsToUpdate = Object.keys(flagForm)
    .filter((flagFormKey) => !isEqual(flagForm[flagFormKey], currentMeetingValues[flagFormKey]));

  const fieldsToUpdateWithoutEmptyNote = fieldsToUpdate.filter((fieldToUpdate) => {
    if (fieldToUpdate === 'userGeneratedNote') {
      return !!flagForm.userGeneratedNote;
    }

    return true;
  });

  return (
    <div className="card-header animated fadeInUp">
      <div className="row">
        <div className="col-8">
          <span className={flagClassName}>
            <ChatLeftText size="1em" />
          </span>
          <span className="ml-4">
            <FormattedMessage id={`adminPanel-header-${ticketType}`} />
          </span>
        </div>
        {fieldsToUpdateWithoutEmptyNote.length > 0 && (
          <div className="col-4 d-flex align-items-center justify-content-end">
            <div className="text-primary mr-2">
              <CircleFill size=".5em" />
            </div>
            <div>
              <TinyText className="mb-0">
                <FormattedMessage id="Unsaved Changes" />
              </TinyText>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SupportTicketHeader.displayName = 'SupportTicketHeader';

SupportTicketHeader.propTypes = {
  currentMeetingValues: meetingShape.isRequired,
  flagForm: flagFormShape.isRequired,
  ticketType: PropTypes.oneOf([
    'newMeeting',
    'flaggedMeeting',
    'changeRequest',
  ]).isRequired,
};

export default SupportTicketHeader;
