import { get, partition, pull } from 'lodash';

class LocationOptionCalculator {
  constructor({
    filters,
    formatMessage,
    sortedGeoData,
  }) {
    this.filters = filters;
    this.formatMessage = formatMessage;
    this.sortedGeoData = sortedGeoData;
  }

  administrativeArea1OptionCalculator() {
    if (this.filters.country) {
      const myAdministrativeArea1Object = get(this.sortedGeoData, [
        this.filters.country,
        'allAdministrativeAreas1',
      ], {});
      return Object.keys(myAdministrativeArea1Object);
    }

    return [];
  }

  administrativeArea2OptionCalculator() {
    if (this.filters.country && this.filters.administrativeArea1) {
      const myAdministrativeArea2Object = get(this.sortedGeoData, [
        this.filters.country,
        'allAdministrativeAreas1',
        this.filters.administrativeArea1,
        'allAdministrativeAreas2',
      ], {});

      return Object.keys(myAdministrativeArea2Object);
    }

    return [];
  }

  buildLocationOptions(key) {
    const locationOptionCalculator = this.getLocationCalculator(key);
    const rawLocationOptions = locationOptionCalculator();

    const locationOptions = rawLocationOptions.map((locationName) => ({
      id: locationName,
      label: locationName,
      value: locationName,
    }));

    const allOption = {
      id: '',
      label: this.formatMessage({ id: `dropdownLabel-all-${key}` }),
      value: '',
    };

    if (this.filters[key]) {
      const myKey = this.filters[key];
      const [myLocationItem, otherLocationItems] = partition(locationOptions, (
        { value }) => value === myKey);

      return [...myLocationItem, allOption, ...otherLocationItems];
    }

    return [allOption, ...locationOptions];
  }

  countryOptionCalculator() {
    const allOptions = Object.keys(this.sortedGeoData);
    pull(allOptions, 'United States');
    return ['United States', ...allOptions];
  }

  getLocationCalculator(key) {
    switch (key) {
      case 'country':
        return this.countryOptionCalculator.bind(this);
      case 'administrativeArea1':
        return this.administrativeArea1OptionCalculator.bind(this);
      case 'administrativeArea2':
        return this.administrativeArea2OptionCalculator.bind(this);
      case 'locality':
        return this.localityOptionCalculator.bind(this);
      default:
        return () => { };
    }
  }

  localityOptionCalculator() {
    const hasCountryFilter = this.filters.country;
    const hasAdministrativeArea1Filter = this.filters.country && this.filters.administrativeArea1;
    const hasAdministrativeArea2Filter = hasAdministrativeArea1Filter
      && this.filters.administrativeArea2;

    const countryObject = hasCountryFilter ? this.sortedGeoData[this.filters.country] : null;
    const administrativeArea1Object = hasAdministrativeArea1Filter
      ? countryObject.allAdministrativeAreas1[this.filters.administrativeArea1]
      : null;
    const administrativeArea2Object = hasAdministrativeArea2Filter
      ? administrativeArea1Object.allAdministrativeAreas2[this.filters.administrativeArea2]
      : null;

    // localities get more specific based on the filters
    if (hasAdministrativeArea2Filter) { return get(administrativeArea2Object, 'allLocalities', []); }
    if (hasAdministrativeArea1Filter) { return get(administrativeArea1Object, 'allLocalities', []); }
    if (hasCountryFilter) { return get(countryObject, 'allLocalities', []); }

    return [];
  }
}

export default LocationOptionCalculator;
