import React, { Fragment } from 'react';
import geoShape from '../../types/geoShape';
import LocationBuilder from './LocationBuilder';

const MeetingLocation = ({ geo }) => {
  const locationBuilder = new LocationBuilder(geo);
  const { locationLine1, locationLine2 } = locationBuilder.build();

  return locationLine1 && (
    <Fragment>
      <small className="d-lg-block d-none text-muted">
        {locationLine1}
        {locationLine2 && (
          <Fragment>
            <br />
            <small><em>{locationLine2}</em></small>
          </Fragment>
        )}
      </small>
      <small className="d-lg-none text-muted">
        {locationLine1}
      </small>
    </Fragment>
  );
};

MeetingLocation.displayName = 'MeetingLocation';

MeetingLocation.propTypes = {
  geo: geoShape.isRequired,
};

export default MeetingLocation;
