/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const Button = ({
  className,
  children,
  disabled,
  isLoading,
  onClick,
  type,
}) => {
  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 'Enter') { return onClick(); }
    return null;
  };

  const myClassName = `btn ${className}`;

  return (
    <button
      className={myClassName}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      type={type}
    >
      <span>
        {children}
      </span>
      {isLoading && (<Spinner className="ml-2 mb-1" color="light" size="sm" />)}
    </button>
  );
};

Button.displayName = 'Button';

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  disabled: false,
  isLoading: false,
  type: 'button',
};

export default Button;
