import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get, pickBy, times } from 'lodash';
import { connectAsync } from 'iguazu';
import { queryResource } from 'iguazu-rest';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useMeta } from '../../hooks/useMeta';
import Spinner from '../../lib/Spinner';
import ContentLoader from '../../lib/ContentLoader';
import MeetingFiltersSideWidget from '../meeting-filters/MeetingFiltersSideWidget';
import Filters from '../meeting-filters/Filters';
import ListSegment from './ListSegment';

const MeetingList = ({
  meetingMeta,
  isLoading,
  loadedWithErrors,
}) => {
  const { activeFilters: filters } = useSelector((state) => state.filters);

  const [lastIds, setLastIds] = useState([0]);
  const resetLastIds = () => setLastIds([0]);
  const addLastId = (lastId) => {
    if (lastIds.includes(lastId)) { return null; }
    return setLastIds([...lastIds, lastId]);
  };

  const [listSegmentCount, setListSegmentCount] = useState(1);
  const resetListSegmentCount = () => setListSegmentCount(1);
  const loadNextSegment = () => {
    setListSegmentCount(listSegmentCount + 1);
  };
  const count = get(meetingMeta, 'count', 0);
  const hasMore = (listSegmentCount * 25) < count;

  const {
    administrativeArea1,
    administrativeArea2,
    country,
    dayOfWeek,
    fellowship,
    locality,
    meetingName,
    tags,
    timeOfDay,
  } = filters;

  useEffect(() => {
    resetLastIds();
    resetListSegmentCount();
  }, [
    administrativeArea1,
    administrativeArea2,
    country,
    dayOfWeek,
    fellowship,
    locality,
    meetingName,
    tags,
    timeOfDay,
  ]);

  const isLoaded = !isLoading()
    && !loadedWithErrors();

  const { mode } = useMeta();

  return (
    <Fragment>
      <div className="row my-4" id="meetingList">
        <div className="col-lg-4 col-md-5 d-none d-lg-block">
          {isLoaded ? (<MeetingFiltersSideWidget />) : (<ContentLoader />)}
        </div>
        <div className="offset-lg-0 offset-md-1" />
        <div className="col-lg-8 col-md-10">
          <div className="d-lg-none">
            {/* This tricks the dropdowns into re-rendering so they have the right initial state */}
            {isLoaded && (
              <div className="card animated fadeIn mb-4">
                <div className="card-body">
                  <Filters />
                </div>
              </div>
            )}
          </div>
          {isLoaded ? (
            <InfiniteScroll
              dataLength={listSegmentCount}
              loader={<Spinner />}
              hasMore={hasMore}
              next={loadNextSegment}
              style={{ overflow: 'inherit' }}
            >
              {times(listSegmentCount, (index) => lastIds[index] !== undefined && (
                <ListSegment
                  addLastId={addLastId}
                  key={`meetingList-listSegment-${lastIds[index]}`}
                  lastId={lastIds[index]}
                  mode={mode}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <Fragment>
              <div className="mb-3">
                <ContentLoader />
              </div>
              <div className="mb-3">
                <ContentLoader />
              </div>
              <div className="mb-3">
                <ContentLoader />
              </div>
              <div className="mb-3">
                <ContentLoader />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

MeetingList.displayName = 'MeetingList';

MeetingList.propTypes = {
  isLoading: PropTypes.func.isRequired,
  loadedWithErrors: PropTypes.func.isRequired,
  meetingMeta: PropTypes.shape({
    count: PropTypes.number,
  }),
};

MeetingList.defaultProps = {
  meetingMeta: {},
};

export const loadDataAsProps = ({ store: { dispatch, getState } }) => {
  const { filters: { activeFilters, timeZone } } = getState();
  const validFilters = pickBy(activeFilters, (item) => !!item || item === 0);
  const validFiltersWithTimeZone = { ...validFilters, timeZone };

  return {
    meetingMeta: () => dispatch(queryResource({
      resource: 'meetingMeta',
      id: validFiltersWithTimeZone,
      opts: {
        query: validFiltersWithTimeZone,
      },
    })),
  };
};

export default connectAsync({ loadDataAsProps })(MeetingList);
