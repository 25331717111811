import contact from './contact';
import fellowships from './fellowships';
import meetingMeta from './meetingMeta';
import meeting from './meeting';
import meetings from './meetings';
import notes from './notes';
import search from './search';
import support from './support';
import users from './users';

export default {
  contact,
  fellowships,
  meetingMeta,
  meeting,
  meetings,
  notes,
  search,
  support,
  users,
};
