import React from 'react';
import { connectAsync } from 'iguazu';
import { queryResource } from 'iguazu-rest';
import { get } from 'lodash';
import { useAuth } from '../../lib/ReactAuthStub';
import PersonCircle from '../../lib/icons/PersonCircle';
import Button from '../../lib/Button';
import TinyText from '../../lib/TinyText';

const AuthButton = () => {
  const {
    isAuthenticated,
    login,
    logout,
  } = useAuth();

  const cta = isAuthenticated()
    ? 'logout'
    : 'login';

  const iconColor = isAuthenticated()
    ? 'danger'
    : 'primary';

  const clickHandler = isAuthenticated()
    ? logout
    : login;

  return (
    <Button className="btn-tertiary" onClick={clickHandler}>
      <div className="d-flex align-items-center">
        <div className={`mr-2 text-${iconColor}`}>
          <PersonCircle size="1em" />
        </div>
        <div>
          <TinyText className="mt-2">{cta}</TinyText>
        </div>
      </div>
    </Button>
  );
};

AuthButton.displayName = 'AuthButton';

export const loadDataAsProps = ({ store: { dispatch }, ownProps: { isAuthenticated, user } }) => {
  if (isAuthenticated) {
    return {
      user: () => dispatch(queryResource({
        resource: 'users',
        id: get(user, 'sub', 10),
      })),
    };
  }
  return {};
};

export default connectAsync({ loadDataAsProps })(AuthButton);
