import PropTypes from 'prop-types';

const meetingShape = PropTypes.shape({
  clickToCallLink: PropTypes.string,
  dayOfWeek: PropTypes.number,
  description: PropTypes.string,
  endTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  fellowship: PropTypes.string,
  geo: PropTypes.shape({
    administrativeArea1: PropTypes.string,
    administrativeArea2: PropTypes.string,
    administrativeArea3: PropTypes.string,
    country: PropTypes.string,
    locality: PropTypes.string,
    neighborhood: PropTypes.string,
    sublocality: PropTypes.string,
    lat: PropTypes.string,
    long: PropTypes.string,
  }),
  hasPassword: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isHidden: PropTypes.bool,
  language: PropTypes.string,
  meetingContact: PropTypes.string,
  meetingName: PropTypes.string,
  meetingPassword: PropTypes.string,
  secureSettings: PropTypes.bool,
  slug: PropTypes.string,
  startTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  tags: PropTypes.arrayOf(PropTypes.shape({
    tagType: PropTypes.string,
    isExclusive: PropTypes.bool,
  })),
  timeOfDay: PropTypes.string,
  videoLink: PropTypes.string,
});

export default meetingShape;
