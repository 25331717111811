import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../lib/Button';
import DashCircle from '../../lib/icons/DashCircle';
import PlusCircle from '../../lib/icons/PlusCircle';
import TinyText from '../../lib/TinyText';
import FlagFormValidator from './FlagFormValidator';

const FlagFormRowButtons = ({
  addRow,
  deleteRow,
  fieldName,
  flagFormValidator,
  formLength,
  rowId,
  rowIndex,
}) => {
  const maxRows = flagFormValidator.dropdownOptions.length;

  const isLastRow = rowIndex === formLength - 1;
  const canAddMoreFields = rowIndex < maxRows - 1;
  const shouldShowAddRowButton = isLastRow
    && canAddMoreFields
    && flagFormValidator.isValidFormField(fieldName);

  const shouldShowDeleteRowButton = formLength > 1;

  const addRowPaddingClass = shouldShowDeleteRowButton
    ? 'mr-4'
    : '';

  return (
    <div className="col-11 my-1 d-flex justify-content-end">
      {shouldShowAddRowButton && (
        <Button
          className={`btn-tertiary text-muted p-0 ${addRowPaddingClass}`}
          onClick={addRow}
        >
          <PlusCircle size="1em" />
          <TinyText className="ml-2 d-inline-block">Add Row</TinyText>
        </Button>
      )}
      {shouldShowDeleteRowButton && (
      <Button
        className="btn-tertiary text-muted p-0"
        onClick={() => deleteRow(rowId, fieldName)}
      >
        <DashCircle size="1em" />
        <TinyText className="ml-2 d-inline-block">Delete Row</TinyText>
      </Button>
      )}
    </div>
  );
};

FlagFormRowButtons.displayName = 'FlagFormRowButtons';

FlagFormRowButtons.propTypes = {
  addRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  flagFormValidator: PropTypes.instanceOf(FlagFormValidator).isRequired,
  formLength: PropTypes.number.isRequired,
  rowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  rowIndex: PropTypes.number.isRequired,
};

export default FlagFormRowButtons;
