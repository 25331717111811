import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import Sticky from 'react-stickynode';
import { useAuth } from '../../lib/ReactAuthStub';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import AuthButton from './AuthButton';
import { toggleFilterModal } from '../../redux/reducers/filters';
import SearchBar from '../search/SearchBar';
import logo from '../../images/logo-50px.png';
import Button from '../../lib/Button';
import TinyText from '../../lib/TinyText';
import Filter from '../../lib/icons/Filter';
import Gear from '../../lib/icons/Gear';
import TopLevelNavButtons from './TopLevelNavButtons';

const Navbar = () => {
  const dispatch = useDispatch();
  const { isAuthorized, isAuthenticated, user } = useAuth();

  return (
    <Fragment>
      <div className="row bg-white">
        <div className="col-12 d-flex justify-content-between align-items-center w-100">
          <div>
            <a
              className={`btn btn-tertiary mb-0 text-black ${isAuthorized('SUPER_ADMIN') ? 'visible' : 'invisible'}`}
              href={isAuthorized('SUPER_ADMIN') ? '/admin' : '#'}
            >
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  <Gear size="1em" />
                </div>
                <div>
                  <TinyText className="mt-2">Admin Panel</TinyText>
                </div>
              </div>
            </a>
          </div>
          <div className="d-flex align-items-center">
            <img alt="icon" src={logo} width="35px" />
            <p className="d-inline ml-2 mb-0">12steps.video</p>
          </div>
          <div>
            <AuthButton isAuthenticated={isAuthenticated} user={user} />
          </div>
        </div>
      </div>
      <Sticky top={0} innerZ={1000}>
        <div id="navbar">
          <nav className="navbar py-0 d-sm-block navbar-light navbar-expand-lg border-bottom">

            <div className="row d-flex align-items-center bg-white">
              <div className="col-md-6">
                <div className="d-flex align-items-center justify-content-between">
                  {/* <div className="mr-2 mt-0 pt-0 d-lg-block d-none">
                    <img alt="icon" src={logo} width="50px" />
                  </div> */}
                  <div className="mt-2 d-none d-md-inline-block">
                    <TopLevelNavButtons />
                  </div>
                </div>
              </div>
              <div className="col-6 d-none d-md-inline-block">
                <div className="row d-flex align-items-center">
                  <div className="col-10">
                    <SearchBar />
                  </div>
                  <div className="col-2">
                    <Button
                      className="btn-tertiary text-dark ml-2 mt-2 p-0"
                      onClick={() => dispatch(toggleFilterModal())}
                    >
                      <Filter size="1em" />
                      <TinyText>
                        <FormattedMessage id="navbarLink-filters" />
                      </TinyText>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <nav className="d-md-none navbar navbar-expand-sm navbar-light bg-white border-bottom sticky-top justify-content-center">
            <div className="my-1 d-lg-none w-75">
              <SearchBar />
            </div>
            <Button
              className="btn-tertiary text-black-50 ml-2"
              onClick={() => dispatch(toggleFilterModal())}
            >
              <Filter size="1em" />
              <TinyText>
                <FormattedMessage id="navbarLink-filters" />
              </TinyText>
            </Button>
          </nav>
        </div>
      </Sticky>
    </Fragment>
  );
};

Navbar.displayName = 'Navbar';

export default Navbar;
