import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import TinyText from '../../lib/TinyText';
import NotesList from '../notes/NotesList';
import SectionDivider from '../../lib/SectionDivider';
import MeetingChanges from './MeetingChanges';
import meetingShape from '../../types/meetingShape';

const HiddenListingFields = ({
  currentMeetingValues,
  flagForm,
  updateFlagForm,
}) => {
  const fieldsToUpdate = Object.keys(flagForm)
    .filter((flagFormKey) => !isEqual(flagForm[flagFormKey], currentMeetingValues[flagFormKey]));
  const sanitizedFieldsToUpdate = fieldsToUpdate
    .filter((fieldName) => !['fellowships', 'ticketId', 'geo', 'requestorEmail'].includes(fieldName));

  const handleInput = ({ target: { name, value } }) => updateFlagForm({ [name]: value });

  const hasChangesToShow = sanitizedFieldsToUpdate.length > 0;

  return (
    <div className="animated fadeIn mt-xl-2">
      <SectionDivider />
      <div className="row mb-4">
        <div className="col-md-12">
          <h6 className="ml-1">
            <FormattedMessage id="meetingItem-hiddenListingFields-header" />
          </h6>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-lg-9 col-md-12">
          <label htmlFor="videoLink" className="ml-1">
            <TinyText>
              <FormattedMessage id="meetingItem-hiddenListingFields-inputLabel-videoLink" />
            </TinyText>
          </label>
          <input
            className="form-control"
            name="videoLink"
            onChange={handleInput}
            type="text"
            value={flagForm.videoLink}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-lg-9 col-md-12">
          <label htmlFor="clickToCallLink" className="ml-1">
            <TinyText>
              <FormattedMessage id="meetingItem-hiddenListingFields-inputLabel-clickToCallLink" />
            </TinyText>
          </label>
          <input
            className="form-control"
            name="clickToCallLink"
            onChange={handleInput}
            type="text"
            value={flagForm.clickToCallLink}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="meetingPassword" className="ml-1">
            <TinyText>
              <FormattedMessage id="meetingItem-hiddenListingFields-inputLabel-meetingPassword" />
            </TinyText>
          </label>
          <div className="input-group mb-2">
            <input
              className="form-control"
              name="meetingPassword"
              onChange={handleInput}
              type="text"
              value={flagForm.meetingPassword}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6">
          <label htmlFor="meetingContact" className="ml-1">
            <TinyText>
              <FormattedMessage id="meetingItem-hiddenListingFields-inputLabel-meetingContact" />
            </TinyText>
          </label>
          <div className="input-group mb-3">
            <input
              className="form-control"
              name="meetingContact"
              onChange={handleInput}
              type="text"
              value={flagForm.meetingContact}
            />
          </div>
        </div>
      </div>
      <SectionDivider />
      <div className="row mb-2">
        <div className="col-12">
          <NotesList
            newNoteValue={flagForm.userGeneratedNote}
            notes={currentMeetingValues.meetingNotes}
            onChange={handleInput}
          />
        </div>
      </div>
      {hasChangesToShow && (
        <Fragment>
          <SectionDivider />
          <MeetingChanges
            currentMeetingValues={currentMeetingValues}
            fellowship={flagForm.fellowship}
            flagForm={flagForm}
            fieldsToUpdate={sanitizedFieldsToUpdate}
            updateFlagForm={updateFlagForm}
          />
        </Fragment>
      )}
    </div>
  );
};

HiddenListingFields.displayName = 'HiddenListingFields';

HiddenListingFields.propTypes = {
  currentMeetingValues: meetingShape.isRequired,
  flagForm: meetingShape.isRequired,
  updateFlagForm: PropTypes.func.isRequired,
};

export default HiddenListingFields;
