import React from 'react';
import PropTypes from 'prop-types';

const Pencil = ({ size, alt }) => (
  <svg
    aria-label={alt}
    className="bi bi-pencil"
    fill="currentColor"
    height={size}
    role="img"
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" d="M11.293 1.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z" clipRule="evenodd" />
    <path fillRule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 00.5.5H4v.5a.5.5 0 00.5.5H5v.5a.5.5 0 00.5.5H6v-1.5a.5.5 0 00-.5-.5H5v-.5a.5.5 0 00-.5-.5H3z" clipRule="evenodd" />
  </svg>
);

Pencil.displayName = 'Pencil';

Pencil.propTypes = {
  alt: PropTypes.string,
  size: PropTypes.string.isRequired,
};

Pencil.defaultProps = {
  alt: '',
};

export default Pencil;
