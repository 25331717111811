import React from 'react';
import PropTypes from 'prop-types';

const NonSaveableFilter = ({
  children,
}) => (
  <div className="row d-flex align-items-baseline mb-3 no-gutters">
    <div className="col-10">
      {children}
    </div>
  </div>
);

NonSaveableFilter.displayName = 'NonSaveableFilter';

NonSaveableFilter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NonSaveableFilter;
