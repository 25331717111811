import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { get, pickBy } from 'lodash';
import { queryResource } from 'iguazu-rest';
import { connectAsync } from 'iguazu';
import { useCookies } from 'react-cookie';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import FilterHeader from './FilterHeader';
import ResultsMeta from '../results-meta/ResultsMeta';
import Button from '../../lib/Button';
import FellowshipDropdown from '../../lib/dropdowns/FellowshipDropdown';
import TagDropdown from '../../lib/dropdowns/TagDropdown';
import TimeOfDayDropdown from '../../lib/dropdowns/TimeOfDayDropdown';
import DayOfWeekDropdown from '../../lib/dropdowns/DayOfWeekDropdown';
import LocationDropdown from '../../lib/dropdowns/LocationDropdown';
import {
  setFilters,
  setPreferences,
  clearFilters,
  resetFilters,
} from '../../redux/reducers/filters';
import SaveableFilter from './SaveableFilter';
import NonSaveableFilter from './NonSaveableFilter';

const LiteFilters = ({ meetingMeta, selectClassName }) => {
  const dispatch = useDispatch();
  const [setCookie] = useCookies(['preferences']).slice(-2); // not using the first item returned from the useCookies() array

  const {
    activeFilters: {
      dayOfWeek,
      administrativeArea1: selectedAdministrativeArea1,
      administrativeArea2: selectedAdministrativeArea2,
      country: selectedCountry,
      fellowship: selectedFellowship,
      locality: selectedLocality,
      tags: selectedTag,
    },
    preferences,
  } = useSelector((state) => state.filters);

  const handleFilterChange = (
    { target: { name, value } }) => dispatch(setFilters({ [name]: value }));

  const handlePreferenceChange = ({ target: { name, value } }, unsetValue) => {
    const currentPreference = get(preferences, [name], '');
    const newValue = currentPreference === value
      ? unsetValue
      : value;

    const updatedPreferences = {
      ...preferences,
      [name]: value,
    };

    setCookie('preferences', updatedPreferences, {
      path: '/', maxAge: 10 * 365 * 24 * 60 * 60, // set cookie to expire in 10 years
    });

    return dispatch(setPreferences({ [name]: newValue }));
  };

  return (
    <Fragment>
      <div className="mt-2 mb-3">
        <FilterHeader>
          <FormattedMessage id="meetingFilters-sectionLabel-meetingFilters" />
        </FilterHeader>
        <SaveableFilter
          currentValue={selectedFellowship}
          name="fellowship"
          onSaveAction={handlePreferenceChange}
          savedValue={preferences.fellowship}
          unsetValue="all"
        >
          <FellowshipDropdown
            className={selectClassName}
            onChange={handleFilterChange}
            showAllFellowshipsOption={true}
          />
        </SaveableFilter>
        {selectedFellowship !== 'all' && (
          <SaveableFilter
            currentValue={selectedTag}
            name="tags"
            onSaveAction={handlePreferenceChange}
            savedValue={preferences.tags}
          >
            <TagDropdown
              className={selectClassName}
              fellowship={selectedFellowship}
              onChange={handleFilterChange}
            />
          </SaveableFilter>
        )}
      </div>
      <div className="mt-2 mb-3">
        <FilterHeader>
          <FormattedMessage id="meetingFilters-sectionLabel-timeFilters" />
        </FilterHeader>
        <NonSaveableFilter>
          <TimeOfDayDropdown className={selectClassName} onChange={handleFilterChange} />
        </NonSaveableFilter>
        <NonSaveableFilter>
          <DayOfWeekDropdown
            className={selectClassName}
            currentValue={dayOfWeek}
            onChange={handleFilterChange}
            showAllDaysOption={true}
          />
        </NonSaveableFilter>
      </div>
      <div className="mt-2">
        <FilterHeader>
          <FormattedMessage id="meetingFilters-sectionLabel-locationFilters" />
        </FilterHeader>
        <SaveableFilter
          name="country"
          currentValue={selectedCountry}
          onSaveAction={handlePreferenceChange}
          savedValue={preferences.country}
        >
          <LocationDropdown className={selectClassName} name="country" onChange={handleFilterChange} />
        </SaveableFilter>
        {selectedCountry && (
          <div className="animated fadeIn">
            <SaveableFilter
              name="administrativeArea1"
              currentValue={selectedAdministrativeArea1}
              onSaveAction={handlePreferenceChange}
              savedValue={preferences.administrativeArea1}
            >
              <LocationDropdown className={selectClassName} name="administrativeArea1" onChange={handleFilterChange} />
            </SaveableFilter>
          </div>
        )}
        {selectedCountry && selectedAdministrativeArea1 && (
          <div className="animated fadeIn">
            <SaveableFilter
              name="administrativeArea2"
              currentValue={selectedAdministrativeArea2}
              onSaveAction={handlePreferenceChange}
              savedValue={preferences.administrativeArea2}
            >
              <LocationDropdown className={selectClassName} name="administrativeArea2" onChange={handleFilterChange} />
            </SaveableFilter>
          </div>
        )}
        {selectedCountry && selectedAdministrativeArea1 && (
          <div className="animated fadeIn">
            <SaveableFilter
              name="locality"
              currentValue={selectedLocality}
              onSaveAction={handlePreferenceChange}
              savedValue={preferences.locality}
            >
              <LocationDropdown className={selectClassName} name="locality" onChange={handleFilterChange} />
            </SaveableFilter>
          </div>
        )}
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <ResultsMeta count={meetingMeta.count} />
        </div>
      </div>
      <div className="row">
        <div className="col-11 d-flex justify-content-between">
          <Button
            className="btn-tertiary btn-sm text-primary"
            onClick={() => dispatch(resetFilters())}
          >
            <small>
              <FormattedMessage id="meetingFilters-button-label-resetDefaults" />
            </small>
          </Button>
          <Button
            className="btn-tertiary btn-sm ml-2 text-danger"
            onClick={() => dispatch(clearFilters())}
          >
            <small>
              <FormattedMessage id="meetingFilters-button-label-clearFilters" />
            </small>
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

LiteFilters.displayName = 'LiteFilters';

LiteFilters.propTypes = {
  meetingMeta: PropTypes.shape({
    count: PropTypes.number,
  }),
  selectClassName: PropTypes.string,
};

LiteFilters.defaultProps = {
  meetingMeta: {},
  selectClassName: '',
};

export const loadDataAsProps = ({ store: { dispatch, getState } }) => {
  const { filters: { activeFilters } } = getState();
  const validFilters = pickBy(activeFilters, (item) => !!item || item === 0);

  return {
    meetingMeta: () => dispatch(queryResource({
      resource: 'meetingMeta',
      id: validFilters,
      opts: {
        query: validFilters,
      },
    })),
  };
};

export default connectAsync({ loadDataAsProps })(LiteFilters);
