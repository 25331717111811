import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useIntl } from '../../lib/ReactIntlAdapter';
import TinyText from '../../lib/TinyText';
import { actionTypes } from '../modals/NotesModal.configs.json';
import { dropdownOptions } from '../flag-form/FlagForm.configs.json';

const NotesListItem = ({
  actionType,
  author,
  content,
  date,
}) => {
  const { formatMessage } = useIntl();
  const { timeZone } = useSelector((state) => state.filters);

  const actionTypeModifier = formatMessage({ id: `notes-actionType-${actionType}` });
  const flagFormOptionsOtherThanNotes = dropdownOptions.slice(0, -1);

  const getNoteContent = () => {
    if (actionType === actionTypes.FLAG_MEETING) {
      /* for flagForm rows other than notes, this allows us to internationalize
         the message the user receives when a meeting has been flagged */
      const isGenericFlagFormContent = flagFormOptionsOtherThanNotes.includes(content);
      return isGenericFlagFormContent
        ? formatMessage({ id: `flagForm-inputPlaceholder-${content}` })
        : content;
    }

    return content;
  };

  const formattedDate = moment(date).add(timeZone, 'minutes').format('MMMM DD, YYYY, h:mm a');

  return (
    <div className="list-group-item">
      <div className="row">
        <div className="col-12">
          <p>
            <em>
              <small>{formattedDate} - {author}</small>
            </em>
            <br />
            <small>
              {actionType === actionTypes.COMMENT ? getNoteContent() : (
                <Fragment>
                  <TinyText className="d-inline mr-2">{`[${actionTypeModifier}]`}</TinyText>
                  <span>{getNoteContent()}</span>
                </Fragment>
              )}
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

NotesListItem.displayName = 'NotesListItem';

NotesListItem.propTypes = {
  actionType: PropTypes.oneOf(actionTypes).isRequired,
  author: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default NotesListItem;
