import fetch from 'isomorphic-fetch';
import { polyfill } from 'es6-promise';

export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILURE = 'API_FAILURE';

polyfill();

// eslint-disable-next-line prefer-destructuring
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const apiRequest = (endpoint, options) => (dispatch) => {
  dispatch({ type: `${API_REQUEST} (${endpoint})`, payload: { endpoint } });

  const assembledUrl = `${BASE_URL}/${endpoint}`;

  const defaultHeaders = {
    'Access-Control-Allow-Origin': BASE_URL,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(assembledUrl, {
    mode: 'cors',
    headers: defaultHeaders,
    ...options,
  })
    .then((res) => res.json())
    .then((payload) => {
      dispatch({ type: `${API_SUCCESS} (${endpoint})`, payload });
      return payload;
    })
    .catch((error) => {
      dispatch({ type: `${API_FAILURE} (${endpoint})`, error });
      return Promise.reject(error);
    });
};

export default apiRequest;
