import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import { useMeta } from '../../hooks/useMeta';
import SupportTicketHeader from '../admin-panel/SupportTicketHeader';
import meetingShape from '../../types/meetingShape';
import flagFormShape from '../../types/flagFormShape';

const MeetingItemHeader = ({
  isFlagMode,
  ticketType,
  currentMeetingValues,
  flagForm,
}) => {
  const { mode } = useMeta();

  const textClass = mode === 'flag'
    ? 'text-danger'
    : 'text-dark';

  const baseMessageId = `flagForm-header-${mode}`;
  const flagModeModifier = isFlagMode
    ? 'expanded'
    : 'collapsed';
  const messageId = mode === 'admin'
    ? `${baseMessageId}-${flagModeModifier}`
    : baseMessageId;

  const animationClass = mode === 'admin'
    ? ''
    : 'fadeInUp';

  const shouldShowMeetingItemHeader = isFlagMode
    || mode === 'admin';

  return shouldShowMeetingItemHeader && (
    <Fragment>
      {mode === 'admin' ? (
        <SupportTicketHeader
          ticketType={ticketType}
          currentMeetingValues={currentMeetingValues}
          flagForm={flagForm}
        />
      ) : (
        <div className={`card-header animated ${animationClass}`}>
          <div className="row">
            <div className="col-md-10">
              <span className={textClass}>
                <FormattedMessage id={messageId} />
              </span>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

MeetingItemHeader.displayName = 'MeetingItemHeader';

MeetingItemHeader.propTypes = {
  currentMeetingValues: meetingShape,
  flagForm: flagFormShape,
  isFlagMode: PropTypes.bool.isRequired,
  ticketType: PropTypes.oneOf([
    'newMeeting',
    'flaggedMeeting',
    'changeRequest',
  ]),
};

MeetingItemHeader.defaultProps = {
  currentMeetingValues: {},
  flagForm: {},
  ticketType: [],
};

export default MeetingItemHeader;
