import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const MenuPortalContext = createContext();

export const MenuPortalContextProvider = ({ children, isSticky }) => (
  <MenuPortalContext.Provider value={{ isSticky }}>
    {children}
  </MenuPortalContext.Provider>
);

MenuPortalContextProvider.propTypes = {
  isSticky: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

MenuPortalContextProvider.defaultProps = {
  isSticky: false,
};

export const useMenuPortalContext = () => useContext(MenuPortalContext);
