/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import meetingShape from '../../types/meetingShape';
import MeetingChangeItem from './MeetingChangeItem';

const MeetingChanges = ({
  currentMeetingValues,
  fellowship,
  flagForm,
  fieldsToUpdate,
  updateFlagForm,
}) => {
  const getSanitizedCurrentValue = (fieldName) => {
    if (fieldName === 'geoPreview') {
      return currentMeetingValues.geo;
    }

    return currentMeetingValues[fieldName];
  };

  return (
    <div className="animated fadeIn">
      <div className="row mb-3">
        <div className="col-12 d-flex justify-content-center">
          <h6 className="text-center mr-1">
            <FormattedMessage id="Current Changes to This Meeting" />
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className="list-group list-group-flush">
            {fieldsToUpdate.map((fieldName) => (
              <MeetingChangeItem
                fellowship={fellowship}
                fieldName={fieldName}
                currentValue={getSanitizedCurrentValue(fieldName)}
                newValue={flagForm[fieldName]}
                undoChange={() => updateFlagForm({ [fieldName]: getSanitizedCurrentValue(fieldName) })}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

MeetingChanges.displayName = 'MeetingChanges';

MeetingChanges.propTypes = {
  currentMeetingValues: meetingShape.isRequired,
  fellowship: PropTypes.string.isRequired,
  fieldsToUpdate: PropTypes.arrayOf(PropTypes.string).isRequired,
  flagForm: meetingShape.isRequired,
  updateFlagForm: PropTypes.func.isRequired,
};

export default MeetingChanges;
