import { useState } from 'react';

const useForm = (initialState = {}) => {
  const [formValues, setFormValues] = useState(initialState);

  const resetFormField = (fieldToReset = '') => {
    if (fieldToReset) {
      return setFormValues({
        ...formValues,
        [fieldToReset]: initialState[fieldToReset] || null,
      });
    }

    return null;
  };

  const updateFormFields = (updatedFields = {}) => setFormValues({
    ...formValues,
    ...updatedFields,
  });

  const resetForm = () => setFormValues(initialState);

  return {
    formValues,
    resetForm,
    resetFormField,
    updateFormFields,
  };
};

export default useForm;
