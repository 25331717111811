/* eslint-disable class-methods-use-this */
import InputValidator from '../../lib/classes/InputValidator';
import {
  dropdownOptions,
  customInputFieldTypes,
  emailRequiredFieldTypes,
} from './FlagForm.configs.json';

export default class FlagFormValidator extends InputValidator {
  constructor(flagForm) {
    super();
    this.flagForm = flagForm;
    this.dropdownOptions = dropdownOptions;
    this.customInputFieldTypes = customInputFieldTypes;
    this.emailRequiredFieldTypes = emailRequiredFieldTypes || [];
  }

  getFieldType(fieldName) {
    if (!fieldName) { return ''; }
    const [fieldType] = fieldName.split('-');
    return fieldType;
  }

  getFieldAction(fieldName) {
    if (!fieldName) { return ''; }
    const fieldAction = fieldName.split('-').pop();
    return fieldAction;
  }

  validate(fieldName) {
    const fieldType = this.getFieldType(fieldName);
    const fieldAction = this.getFieldAction(fieldName);

    if (!this.customInputFieldTypes.includes(fieldType)) { return true; }

    switch (fieldAction) {
      case 'requestorEmail':
        return this.validateEmail(this.flagForm.requestorEmail);
      case 'updateContactEmail':
        return this.validateEmail(this.flagForm[fieldName]);
      case 'updateVideoLink':
        return this.validateURL(this.flagForm[fieldName]);
      default:
        return !this.isNully(this.flagForm[fieldName]);
    }
  }

  isValidFormField(fieldName) {
    return this.dropdownOptions.includes(fieldName);
  }

  getFieldNamesWithContent() {
    return Object.keys(this.flagForm)
      .filter((fieldName) => {
        if (fieldName === 'fellowships') {
          // filter out the fellowships array if it is empty or has falsy content
          return this.flagForm.fellowships.length > 0
            && !!this.flagForm.fellowships[0];
        }

        return !this.isNully(this.flagForm[fieldName]);
      });
  }

  hasEmailRequiredFields() {
    const fieldsWithContent = this.getFieldNamesWithContent();

    const activeFieldTypes = fieldsWithContent.map((fieldName) => this.getFieldType(fieldName));
    const emailRequiredFields = activeFieldTypes
      .filter((fieldType) => this.emailRequiredFieldTypes.includes(fieldType));

    return emailRequiredFields.length > 0;
  }

  isDropdownOptionAvailable(fieldName, formRows) {
    const currentFormRowsByFieldName = formRows.map(({ fieldName: name }) => name);
    if (!this.dropdownOptions.includes(fieldName)) { return false; }
    return !currentFormRowsByFieldName.includes(fieldName);
  }

  isSubmitButtonDisabled() {
    return this.hasEmailRequiredFields() && !this.flagForm.requestorEmail;
  }

  isBooleanFieldType(fieldName) {
    const myFieldType = this.getFieldType(fieldName);
    return !this.customInputFieldTypes.includes(myFieldType);
  }

  isInputFieldDisabled(fieldName) {
    return !this.dropdownOptions.includes(fieldName)
      || this.isBooleanFieldType(fieldName);
  }

  shouldReflectInputValidationClass(fieldName) {
    const myFieldType = this.getFieldType(fieldName);

    if (myFieldType === 'notes') { return false; }

    return this.customInputFieldTypes.includes(myFieldType)
      && !this.isNully(this.flagForm[fieldName]);
  }

  isEmpty() {
    return Object.keys(this.flagForm).every((fieldName) => !this.validate(fieldName));
  }

  hasInvalidFields() {
    const fieldNamesWithContent = this.getFieldNamesWithContent();
    return fieldNamesWithContent.some((fieldName) => !this.validate(fieldName));
  }
}
