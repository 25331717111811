import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '../../lib/ReactIntlAdapter';
import { useMeta } from '../../hooks/useMeta';
import { formatHour } from '../../utils/formatHour';

const MeetingMeta = ({
  fellowship,
  localStartTime,
  startTime,
  endTime,
  dayOfWeek,
}) => {
  const { formatMessage } = useIntl();
  const { mode } = useMeta();

  const fellowshipShortName = formatMessage({ id: `fellowshipShortName-${fellowship || null}` });
  const dayOfWeekByName = formatMessage({ id: `day-${dayOfWeek}-name` });

  const formattedMeetingTime = `${formatHour(startTime)} - ${formatHour(endTime)}`;
  const theirTime = `(${formatHour(localStartTime)} ${formatMessage({ id: 'meetingInfo-timeIndicator-theirs' })})`;

  const today = new Date().getDay();
  const meetingMeta = dayOfWeek === today && mode !== 'admin'
    ? `${fellowshipShortName} - ${formattedMeetingTime}`
    : `${fellowshipShortName} - ${dayOfWeekByName} - ${formattedMeetingTime}`;

  return (
    <Fragment>
      <div className="d-none d-lg-block mb-2">
        <h6 className="card-subtitle">
          {meetingMeta} {startTime !== localStartTime && (<small><em>{theirTime}</em></small>)}
        </h6>
      </div>
      <div className="d-lg-none mb-2">
        <p className="card-subtitle">
          {meetingMeta} {startTime !== localStartTime && (<small><em>{theirTime}</em></small>)}
        </p>
      </div>
    </Fragment>
  );
};

MeetingMeta.displayName = 'MeetingMeta';

MeetingMeta.propTypes = {
  fellowship: PropTypes.string,
  localStartTime: PropTypes.number,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  dayOfWeek: PropTypes.number.isRequired,
};

MeetingMeta.defaultProps = {
  fellowship: null,
  localStartTime: '',
};

export default MeetingMeta;
