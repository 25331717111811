/* eslint-disable import/prefer-default-export */
import adminReducer from './admin';
import geoReducer from './geo';
import fellowshipsReducer from './fellowships';
import feedbackReducer from './feedback';
import filtersReducer from './filters';
import notesReducer from './notes';
import searchReducer from './search';
import sharingReducer from './sharing';

export { setFilters } from './filters';

const reducers = {
  admin: adminReducer,
  feedback: feedbackReducer,
  filters: filtersReducer,
  geo: geoReducer,
  fellowships: fellowshipsReducer,
  notes: notesReducer,
  search: searchReducer,
  sharing: sharingReducer,
};

export default reducers;
