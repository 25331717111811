import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import useForm from '../../hooks/useForm';
import { useMeta } from '../../hooks/useMeta';
import { experiencePhases } from './LinkConfirmationModal.configs.json';
import MeetingEntryPrompt from './MeetingEntryPrompt';
import SuccessExperience from './SuccessExperience';
import ErrorExperience from './ErrorExperience';

const LinkConfirmationModal = ({
  isModalOpen,
  hasPassword,
  meetingId,
  meetingName,
  meetingPassword,
  toggleLinkConfirmationModal,
}) => {
  const { mode } = useMeta();
  const [experiencePhase, setExperiencePhase] = useState(experiencePhases.initialPrompt);

  const {
    formValues: animationClasses,
    updateFormFields: updateAnimationClasses,
    resetForm: resetAnimationClasses,
  } = useForm({
    check: '',
    cross: '',
    deadBrowser: 'fadeIn',
    mail: 'fadeInLeft',
  });

  const resetModal = () => {
    resetAnimationClasses();
    setExperiencePhase(experiencePhases.initialPrompt);
    toggleLinkConfirmationModal();
  };

  const headerMessageId = meetingPassword
    ? 'linkConfirmation-hasPassword-header'
    : 'linkConfirmation-noPassword-header';

  return mode === 'default' && (
    <Modal isOpen={isModalOpen} toggle={resetModal}>
      <ModalHeader toggle={resetModal}>
        <FormattedMessage id={headerMessageId} values={{ meetingName }} />
      </ModalHeader>
      <ModalBody>
        {experiencePhase === experiencePhases.initialPrompt && (
          <MeetingEntryPrompt
            animationClasses={animationClasses}
            hasPassword={hasPassword}
            meetingPassword={meetingPassword}
            setExperiencePhase={setExperiencePhase}
            updateAnimationClasses={updateAnimationClasses}
          />
        )}
        {experiencePhase === experiencePhases.successfulEntry && (
          <SuccessExperience resetModal={resetModal} />
        )}
        {experiencePhase === experiencePhases.failedEntry && (
          <ErrorExperience
            animationClasses={animationClasses}
            meetingId={meetingId}
            meetingPassword={meetingPassword}
            resetModal={resetModal}
            updateAnimationClasses={updateAnimationClasses}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

LinkConfirmationModal.displayName = 'LinkConfirmationModal';

LinkConfirmationModal.propTypes = {
  hasPassword: PropTypes.bool,
  isModalOpen: PropTypes.bool.isRequired,
  meetingId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  meetingName: PropTypes.string.isRequired,
  meetingPassword: PropTypes.string,
  toggleLinkConfirmationModal: PropTypes.func.isRequired,
};

LinkConfirmationModal.defaultProps = {
  hasPassword: false,
  meetingPassword: '',
};

export default LinkConfirmationModal;
