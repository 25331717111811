import React from 'react';
import PropTypes from 'prop-types';

const DashCircle = ({ size }) => (
  <svg className="bi bi-flag-fill" width={size} height={size} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z" clipRule="evenodd" />
    <path fillRule="evenodd" d="M3.5 8a.5.5 0 01.5-.5h8a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clipRule="evenodd" />
  </svg>
);

DashCircle.displayName = 'DashCircle';

DashCircle.propTypes = {
  size: PropTypes.string.isRequired,
};

export default DashCircle;
