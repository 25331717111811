import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from '../../lib/ReactIntlAdapter';
import TinyText from '../../lib/TinyText';
import Button from '../../lib/Button';
import LocationBuilder from '../meeting-location/LocationBuilder';
import ArrowCounterClockwise from '../../lib/icons/ArrowCounterClockwise';
import { formatHour } from '../../utils/formatHour';

const MeetingChangeItem = ({
  fellowship,
  fieldName,
  currentValue,
  newValue,
  undoChange,
}) => {
  const { formatMessage } = useIntl();

  const booleanFields = ['isHidden', 'hasPassword'];
  const timeFields = ['startTime', 'endTime'];
  const formattedMessageFields = [...booleanFields, 'dayOfWeek', 'language', 'fellowship'];

  const fieldNameLabelId = ['isHidden', 'hasPassword'].includes(fieldName)
    ? `meetingChanges-${fieldName}`
    : `flagForm-dropdownOption-supportTicket-${fieldName}`;

  const getMessageIdForValue = (value) => {
    if (booleanFields.includes(fieldName)) {
      return `meetingChanges-${fieldName}-${value}`;
    }

    if (fieldName === 'dayOfWeek') {
      return `day-${value}-name`;
    }

    if (fieldName === 'language') {
      return `languageName-${value}`;
    }

    if (fieldName === 'fellowship') {
      return `fellowshipTitle-${value}`;
    }

    return value;
  };

  const processValue = (value) => {
    if (timeFields.includes(fieldName)) {
      return formatHour(value);
    }

    if (fieldName === 'geoPreview') {
      const locationBuilder = new LocationBuilder(value);
      const { locationLine1, locationLine2 } = locationBuilder.build();

      return `${locationLine1}, ${locationLine2}`;
    }

    if (fieldName === 'tags') {
      const sanitizedTagsArray = value || [];

      // this controls for incoming tags from current meeting item that don't have labels
      const tagsWithLabels = sanitizedTagsArray.map(({ tagType }) => ({
        label: formatMessage({ id: `meetingTag-${fellowship}-${tagType}` }),
      }));
      const labelledTags = tagsWithLabels.map(({ label }) => label);

      return labelledTags.join(', ') || 'No tags selected';
    }

    return typeof value === 'string'
      ? value
      : JSON.stringify(value);
  };

  return (
    <li className="list-group-item">
      <div className="row d-flex align-items-start">
        <div className="col-md-3 mb-2">
          <small>
            <FormattedMessage id={fieldNameLabelId} />
          </small>
        </div>
        <div className="col-md-7 col-10">
          {fieldName === 'userGeneratedNote' ? (
            <div className="row mt-1">
              <div className="col-12 d-flex align-items-center">
                <small>
                  {newValue}
                </small>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <TinyText className="text-muted mr-2">[old]</TinyText>
                  <small className="text-muted">
                    {formattedMessageFields.includes(fieldName) ? (
                      <FormattedMessage id={getMessageIdForValue(currentValue)} />
                    ) : processValue(currentValue)}
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <TinyText className="mr-2">[new]</TinyText>
                  <small>
                    {formattedMessageFields.includes(fieldName) ? (
                      <FormattedMessage id={getMessageIdForValue(newValue)} />
                    ) : processValue(newValue)}
                  </small>
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <div className="col-2">
          <Button className="btn-tertiary p-0 float-right" onClick={undoChange}>
            <span className="text-danger">
              <ArrowCounterClockwise size="1em" />
            </span>
            <TinyText>
              <FormattedMessage id="meetingChanges-undo" />
            </TinyText>
          </Button>
        </div>
      </div>
    </li>
  );
};

MeetingChangeItem.displayName = 'MeetingChangeItem';

MeetingChangeItem.propTypes = {
  fellowship: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  newValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  undoChange: PropTypes.func.isRequired,
};

export default MeetingChangeItem;
