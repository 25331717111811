import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  partition,
  sortBy,
  uniqBy,
} from 'lodash';
import { useIntl } from '../../lib/ReactIntlAdapter';
import { useMeta } from '../../hooks/useMeta';
import NonExclusiveTag from './NonExclusiveTag';
import ExclusiveTag from './ExclusiveTag';
import tagShape from '../../types/tagShape';

const MeetingTags = ({
  fellowship,
  language,
  meetingId,
  tags,
}) => {
  const { fellowships } = useMeta();
  const { locale } = useIntl();
  const sanitizedMeetingLanguage = language || locale;

  const isForeignLanguage = sanitizedMeetingLanguage !== locale;

  const myFellowship = fellowships.find(({ name }) => name === fellowship) || { tags: [] };
  const { tags: myFellowshipTags } = myFellowship;

  // this protects against a tags array that arrives with undefined as the first member
  const sanitizedTags = tags && tags[0] ? tags : [];

  const uniqueTags = uniqBy(sanitizedTags, 'tagType');
  const uniqueTagsByName = uniqueTags.map(({ tagType }) => tagType);
  const uniqueTagsWithExclusiveIndicator = myFellowshipTags
    .filter(({ tagType }) => uniqueTagsByName.includes(tagType));

  const [exclusiveTags, nonExclusiveTags] = partition(uniqueTagsWithExclusiveIndicator, 'isExclusive');

  const sortedExclusiveTags = sortBy(exclusiveTags, ['tagType']);
  const hasExclusiveTags = sortedExclusiveTags.length > 0;

  const sortedNonExclusiveTags = sortBy(nonExclusiveTags, ['tagType']);
  const sortedNonExclusiveTagsWithForeignLanguage = isForeignLanguage
    ? [{ tagType: 'foreignLanguage' }, ...sortedNonExclusiveTags]
    : sortedNonExclusiveTags;

  const hasNonExclusiveTags = sortedNonExclusiveTagsWithForeignLanguage.length > 0;

  return (
    <div>
      {hasExclusiveTags && sortedExclusiveTags.map(({ tagType }, index) => (
        <ExclusiveTag
          fellowship={fellowship}
          isLastItem={index === sortedExclusiveTags.length - 1}
          key={`meetingItem-${meetingId}-tag-${tagType}`}
          tagType={tagType}
        />
      ))}
      {hasNonExclusiveTags && (
        <Fragment>
          <p className="d-none d-lg-block">
            <em>
              {hasNonExclusiveTags && sortedNonExclusiveTagsWithForeignLanguage
                .map(({ tagType }, index) => (
                  <NonExclusiveTag
                    fellowship={fellowship}
                    isLastItem={index === sortedNonExclusiveTagsWithForeignLanguage.length - 1}
                    key={`meetingItem-${meetingId}-tag-${tagType}`}
                    language={sanitizedMeetingLanguage}
                    tagType={tagType}
                  />
                ))}
            </em>
          </p>
          <small className="card-text d-lg-none">
            <em>
              {hasNonExclusiveTags && sortedNonExclusiveTagsWithForeignLanguage
                .map(({ tagType }, index) => (
                  <NonExclusiveTag
                    fellowship={fellowship}
                    isLastItem={index === sortedNonExclusiveTagsWithForeignLanguage.length - 1}
                    key={`meetingItem-${meetingId}-tag-${tagType}`}
                    language={sanitizedMeetingLanguage}
                    tagType={tagType}
                  />
                ))}
            </em>
          </small>
        </Fragment>
      )}
    </div>
  );
};

MeetingTags.displayName = 'MeetingTags';

MeetingTags.propTypes = {
  fellowship: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  meetingId: PropTypes.number,
  tags: PropTypes.arrayOf(tagShape).isRequired,
};

MeetingTags.defaultProps = {
  meetingId: 0,
};

export default MeetingTags;
