import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import useToggle from '../../hooks/useToggle';
import NotesListItem from './NotesListItem';
import Button from '../../lib/Button';
import TinyText from '../../lib/TinyText';

const NotesList = ({
  newNoteValue,
  notes,
  onChange,
}) => {
  const [shouldShowAllNotes, toggleShowingAllNotes] = useToggle(false);

  const sanitizedNotes = notes || [];
  const notesByMostRecentOrder = sortBy(notes, (note) => note.date).reverse();

  const notesToShow = shouldShowAllNotes
    ? notesByMostRecentOrder
    : notesByMostRecentOrder.slice(0, 3);

  const actionButtonMessageId = shouldShowAllNotes
    ? 'notes-prompt-collapse'
    : 'notes-prompt-loadMore';

  return (
    <div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <h6 className="text-center mr-1">
            <FormattedMessage id="notes-header" />
          </h6>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          <div className="list-group list-group-flush">
            {notesToShow.length > 0 ? notesToShow.map((note) => (
              <NotesListItem
                actionType={note.actionType}
                author={note.createdByUsername}
                content={note.note}
                date={note.createdAt}
              />
            )) : (
              <div className="d-flex justify-content-center my-2">
                <small>
                  <em>
                    <FormattedMessage id="notes-message-noNotes" />
                  </em>
                </small>
              </div>
            )}
          </div>
          {sanitizedNotes.length > 3 && (
            <div className="my-3 d-flex justify-content-center">
              <Button className="btn-tertiary" onClick={toggleShowingAllNotes}>
                <small className="text-primary">
                  <FormattedMessage id={actionButtonMessageId} />
                </small>
              </Button>
            </div>
          )}
          <div className="row d-flex align-items-center mt-4">
            <div className="col-12">
              <label htmlFor="newMeetingNote">
                <TinyText>
                  <FormattedMessage id="notes-label-newNote" />
                </TinyText>
              </label>
              <textarea
                name="userGeneratedNote"
                className="form-control"
                onChange={onChange}
                value={newNoteValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NotesList.displayName = 'NotesList';

NotesList.propTypes = {
  newNoteValue: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.shape({
    author: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func.isRequired,
};

NotesList.defaultProps = {
  newNoteValue: '',
  notes: [],
};

export default NotesList;
