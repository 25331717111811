export const CLEAR_SEARCH_TERM = 'CLEAR_SEARCH_TERM';
export const CLEAR_SEARCH_OPTIONS = 'CLEAR_SEARCH_OPTIONS';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';

export const clearSearchOptions = () => ({
  type: CLEAR_SEARCH_OPTIONS,
});

export const clearSearchTerm = () => ({
  type: CLEAR_SEARCH_TERM,
});

export const resetSearch = () => ({
  type: RESET_SEARCH,
});

export const setSearchOptions = (payload) => ({
  type: SET_SEARCH_OPTIONS,
  payload,
});

export const setSearchTerm = (payload) => ({
  type: SET_SEARCH_TERM,
  payload,
});

const initialState = {
  searchOptions: [],
  searchTerm: '',
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SEARCH_OPTIONS:
      return { ...state, searchOptions: [] };
    case CLEAR_SEARCH_TERM:
      return { ...state, searchTerm: '' };
    case RESET_SEARCH:
      return initialState;
    case SET_SEARCH_OPTIONS:
      return { ...state, searchOptions: action.payload };
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };
    default:
      return state;
  }
};

export default searchReducer;
