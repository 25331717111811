import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import Button from '../../lib/Button';
import TinyText from '../../lib/TinyText';
import Star from '../../lib/icons/Star';
import StarFill from '../../lib/icons/StarFill';

const SaveableFilter = ({
  children,
  currentValue,
  name,
  onSaveAction,
  savedValue,
  unsetValue,
}) => (
  <div className="row d-flex align-items-center mb-2 no-gutters">
    <div className="col-10">
      {children}
    </div>
    {currentValue !== unsetValue && (
      <div className="col-1">
        <Button
          className="btn-sm btn-tertiary ml-2 py-0"
          onClick={() => onSaveAction({ target: { name, value: currentValue } }, unsetValue)}
        >
          {currentValue === savedValue ? (
            <div className="animated fadeIn mt-lg-0">
              <span className="text-warning">
                <StarFill className="text-warning" size="1.25em" />
              </span>
              <TinyText className="text-dark mb-0">
                <FormattedMessage id="meetingFilters-saveableFilter-iconCaption-saved" />
              </TinyText>
            </div>
          ) : (
            <div className="animated fadeIn mt-0">
              <span className="text-muted ml-1">
                <Star className="text-warning" size="1.25em" />
              </span>
              <TinyText className="text-dark mb-0 ml-1">
                <FormattedMessage id="meetingFilters-saveableFilter-iconCaption-save" />
              </TinyText>
            </div>
          )}
        </Button>
      </div>
    )}
  </div>
);

SaveableFilter.displayName = 'SaveableFilter';

SaveableFilter.propTypes = {
  children: PropTypes.node.isRequired,
  currentValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSaveAction: PropTypes.func.isRequired,
  savedValue: PropTypes.string,
  unsetValue: PropTypes.string,
};

SaveableFilter.defaultProps = {
  savedValue: '',
  unsetValue: '',
};

export default SaveableFilter;
