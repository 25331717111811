import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { navigate } from '@reach/router';
import { AuthProvider } from '../lib/ReactAuthStub';
import createStore from './createStore';

// eslint-disable-next-line react/display-name,react/prop-types
const wrapper = ({ children }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  return (
    <ReduxProvider store={store}>
      <AuthProvider
        navigate={navigate}
        auth0_domain={process.env.REACT_APP_AUTH0_DOMAIN}
        auth0_client_id={process.env.REACT_APP_AUTH0_CLIENTID}
        customPropertyNamespace={process.env.REACT_APP_AUTH0_CUSTOM_PROPERTY_NAMESPACE}
      >
        {children}
      </AuthProvider>
    </ReduxProvider>
  );
};

export default wrapper;
