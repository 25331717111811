import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from '../../lib/ReactIntlAdapter';
import Button from '../../lib/Button';
import UiCheck from '../../lib/svgArt/UiCheck.svg';
import UiCross from '../../lib/svgArt/UiCross.svg';
import { experiencePhases } from './LinkConfirmationModal.configs.json';

const MeetingEntryPrompt = ({
  animationClasses,
  meetingPassword,
  setExperiencePhase,
  updateAnimationClasses,
}) => {
  const { formatMessage } = useIntl();

  const handleSelection = (selectionType) => {
    updateAnimationClasses({
      check: 'fadeOutLeft',
      cross: 'fadeOutRight',
    });
    setExperiencePhase(selectionType);
  };

  return (
    <Fragment>
      {meetingPassword && (
        <div className="row mt-4">
          <div className="col-md-12">
            <p className="text-center">
              <span><FormattedMessage id="linkConfirmation-hasPassword-prompt-prefix" /></span>
              <strong className="text-danger">{meetingPassword}</strong>
              <span><FormattedMessage id="linkConfirmation-hasPassword-prompt-suffix" /></span>
              <br />
              <small>
                <em>
                  <FormattedMessage id="linkConfirmation-hasPassword-disclaimer" />
                </em>
              </small>
            </p>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-6 col-6">
          <Button
            className={`btn-tertiary animated ${animationClasses.check}`}
            onClick={() => handleSelection(experiencePhases.successfulEntry)}
          >
            <div className="p-5 w-100">
              <img
                alt={formatMessage({ id: 'linkConfirmation-promptMessage-yes' })}
                className="animated fadeIn"
                src={UiCheck}
                width="96"
                height="96"
              />
            </div>
            <p className="text-center">
              <FormattedMessage id="linkConfirmation-promptMessage-yes" />
            </p>
          </Button>
        </div>
        <div className="col-sm-6 col-6">
          <Button
            className={`btn-tertiary animated ${animationClasses.cross}`}
            onClick={() => handleSelection(experiencePhases.failedEntry)}
          >
            <div className="p-5 w-100">
              <img
                alt={formatMessage({ id: 'linkConfirmation-promptMessage-no' })}
                className="animated fadeIn"
                src={UiCross}
                width="96"
                height="96"
              />
            </div>
            <p className="text-center">
              <FormattedMessage id="linkConfirmation-promptMessage-no" />
            </p>
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

MeetingEntryPrompt.displayName = 'MeetingEntryPrompt';

MeetingEntryPrompt.propTypes = {
  animationClasses: PropTypes.shape({
    check: PropTypes.string,
    cross: PropTypes.string,
  }).isRequired,
  meetingPassword: PropTypes.string,
  setExperiencePhase: PropTypes.func.isRequired,
  updateAnimationClasses: PropTypes.func.isRequired,
};

MeetingEntryPrompt.defaultProps = {
  meetingPassword: '',
};

export default MeetingEntryPrompt;
