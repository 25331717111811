import React, { useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { useAuth } from '../lib/ReactAuthStub';
import Layout from '../components/Layout';

const Auth0CallbackPage = () => {
  const { handleAuthentication } = useAuth();
  useEffect(() => {
    handleAuthentication();
  }, [handleAuthentication]);

  return (
    <Layout>
      <Spinner />
    </Layout>
  );
};

export default Auth0CallbackPage;
