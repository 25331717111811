import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import EnhancedSelect from './EnhancedSelect';
import Dropdown from '../Dropdown';

const MobileSafeDropdown = ({
  currentValue,
  handleBlur,
  handleInput,
  name,
  options,
  placeholder,
}) => {
  const handleDropdownInput = ({ target: { value } }) => handleInput(name, value);

  return (
    <Fragment>
      <div className="d-none d-lg-block">
        <EnhancedSelect
          handleInput={handleInput}
          name={name}
          onBlur={handleBlur}
          options={options}
          placeholder={placeholder}
          value={currentValue}
        />
      </div>
      <div className="d-lg-none">
        <Dropdown
          buttonClass="btn-outline-secondary"
          label={placeholder}
          name={name}
          onBlur={handleBlur}
          onChange={handleDropdownInput}
          options={options}
          value={currentValue}
        />
      </div>
    </Fragment>
  );
};

MobileSafeDropdown.displayName = 'MobileSafeDropdown';

MobileSafeDropdown.propTypes = {
  currentValue: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  handleBlur: PropTypes.func,
  handleInput: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  })).isRequired,
  placeholder: PropTypes.string.isRequired,
};

MobileSafeDropdown.defaultProps = {
  currentValue: null,
  handleBlur: () => {},
};

export default MobileSafeDropdown;
