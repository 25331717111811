import React from 'react';
import PropTypes from 'prop-types';

const Filter = ({ size, alt }) => (
  <svg
    aria-label={alt}
    className="bi bi-filter-square"
    fill="currentColor"
    height={size}
    role="img"
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
  </svg>
);

Filter.displayName = 'Filter';

Filter.propTypes = {
  alt: PropTypes.string,
  size: PropTypes.string.isRequired,
};

Filter.defaultProps = {
  alt: '',
};

export default Filter;
