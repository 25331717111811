import React from 'react';
import PropTypes from 'prop-types';
import { times } from 'lodash';
import { useIntl } from '../ReactIntlAdapter';
import Dropdown from '../Dropdown';

const DayOfWeekDropdown = ({
  className, currentValue, onChange, showAllDaysOption,
}) => {
  const { formatMessage } = useIntl();
  const today = new Date().getDay().toString();

  const allDays = times(7, String);
  const todaysIndex = allDays.indexOf(today);

  const firstOptionIndex = currentValue || todaysIndex;

  const daysBeforeFirstOption = allDays.slice(0, firstOptionIndex);
  const daysFirstOptionAndAfter = allDays.slice(firstOptionIndex);
  const daysInOrder = [...daysFirstOptionAndAfter, ...daysBeforeFirstOption];

  const dayOfWeekOptions = daysInOrder
    .map((index) => ({
      id: index,
      label: formatMessage({ id: `day-${index}-name` }),
      value: index,
    }));

  const allDaysOfWeekOption = {
    id: '',
    label: formatMessage({ id: 'day-null-name' }),
    value: '',
  };

  const optionsToShow = showAllDaysOption
    ? [...dayOfWeekOptions, allDaysOfWeekOption]
    : dayOfWeekOptions;

  return (
    <Dropdown
      buttonClass={`btn-outline-secondary ${className}`}
      label={formatMessage({ id: 'dropdownLabel-timeOfDay' })}
      name="dayOfWeek"
      onChange={onChange}
      options={optionsToShow}
    />
  );
};

DayOfWeekDropdown.displayName = 'DayOfWeekDropdown';

DayOfWeekDropdown.propTypes = {
  className: PropTypes.string,
  currentValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  showAllDaysOption: PropTypes.bool,
};

DayOfWeekDropdown.defaultProps = {
  className: '',
  currentValue: null,
  showAllDaysOption: false,
};

export default DayOfWeekDropdown;
