import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import { graphql, useStaticQuery } from 'gatsby';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { MetaProvider } from '../hooks/useMeta';
import { useIntl } from '../lib/ReactIntlAdapter';
import GeoSorter from '../lib/classes/GeoSorter';
import { setFavorites } from '../redux/reducers/filters';
import { loadGeo } from '../redux/reducers/geo';
import { loadFellowships } from '../redux/reducers/fellowships';

const InitialDataLayer = ({ children, mode = 'default' }) => {
  console.log('initialDataLayer');
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const { favorites } = get(cookies, ['favorites'], []);

  useEffect(() => {
    dispatch(setFavorites(favorites || []));
    dispatch(loadGeo());
    dispatch(loadFellowships());
  }, [dispatch, favorites]);

  const { locale } = useIntl();

  // const data = useStaticQuery(graphql`
  // query MyQuery {
  //   allTags {
  //     edges {
  //       node {
  //         name
  //         tags {
  //           isExclusive
  //           tagType
  //           fellowship
  //         }
  //       }
  //     }
  //   }
  //   allGeoData {
  //     edges {
  //       node {
  //         administrativeArea1
  //         administrativeArea2
  //         country
  //         locality
  //       }
  //     }
  //   }
  // }
  // `);

  // const getFellowFromGraphQL = () => {
  //   const sanitizedQuery = [];
  //   data.allTags.edges.forEach((edge) => {
  //     sanitizedQuery.push(edge.node);
  //   });
  //   return sanitizedQuery;
  // };

  // const getGeoFromGraphQL = () => {
  //   const sanitizedQuery = [];
  //   data.allGeoData.edges.forEach((edge) => {
  //     sanitizedQuery.push(edge.node);
  //   });
  //   return sanitizedQuery;
  // };

  // const fellowships = getFellowFromGraphQL();

  const { isLoading: isGeoLoading, data: geo } = useSelector((state) => state.geo);

  const geoSorter = geo ? new GeoSorter(geo) : null;
  const sortedGeoData = geo ? geoSorter.nestLocationEntries() : {};

  const timezone = new Date().toString().split('(')[1].split(')')[0];

  const {
    isLoading: isFellowshipsLoading,
    data: fellowships,
  } = useSelector((state) => state.fellowships);

  // const fellowships = await fetch('https://steps-video-93623.appspot.com/api/v1/fellowships').then((res) => res.json());

  const isLoaded = !isGeoLoading && !!geo
    && !isFellowshipsLoading && fellowships;

  const meta = {
    fellowships,
    locale,
    mode,
    sortedGeoData,
    timezone,
  };

  return isLoaded ? (
    <MetaProvider value={meta}>
      {children}
    </MetaProvider>
  ) : (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-primary my-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

InitialDataLayer.displayName = 'InitialDataLayer';

InitialDataLayer.propTypes = {
  children: PropTypes.element.isRequired,
  mode: PropTypes.string.isRequired,
};

export default InitialDataLayer;
