import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '../../lib/ReactIntlAdapter';
import TagLabelBuilder from './TagLabelBuilder';

const NonExclusiveTag = ({
  fellowship,
  isLastItem,
  language,
  tagType,
}) => {
  const { formatMessage } = useIntl();

  const labelBuilder = new TagLabelBuilder(formatMessage);
  const tagLabel = labelBuilder.build(fellowship, tagType, language);
  const punctuation = !isLastItem ? ', ' : '';

  const textClass = tagType === 'foreignLanguage'
    ? 'text-danger'
    : 'text-neutral';

  return (
    <small className={textClass}>{tagLabel}{punctuation}</small>
  );
};

NonExclusiveTag.displayName = 'NonExclusiveTag';

NonExclusiveTag.propTypes = {
  fellowship: PropTypes.string.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  tagType: PropTypes.string.isRequired,
};

export default NonExclusiveTag;
