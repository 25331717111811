import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Switch, { Case, Default } from 'react-switch-case';
import { get } from 'lodash';
import useForm from '../../hooks/useForm';
import { useMeta } from '../../hooks/useMeta';
import useToast from '../../hooks/useToast';
import useToggle from '../../hooks/useToggle';
import FlagForm from '../flag-form/FlagForm';
import FlagFormFooter from '../flag-form/FlagFormFooter';
import { useIntl } from '../../lib/ReactIntlAdapter';
import meetingShape from '../../types/meetingShape';
import SupportTicketFooter from '../admin-panel/SupportTicketFooter';
import LinkConfirmationModal from '../link-confirmation/LinkConfirmationModal';
import MeetingLocation from '../meeting-location/MeetingLocation';
import MeetingItemActionButtons from './MeetingItemActionButtons';
import MeetingItemFooter from './MeetingItemFooter';
import MeetingItemHeader from './MeetingItemHeader';
import MeetingItemToast from './MeetingItemToast';
import HiddenListingFields from './HiddenListingFields';
import MeetingInfo from './MeetingInfo';

const MeetingItem = ({
  meeting,
  parentData,
  resetParentField,
  updateParent,
}) => {
  const { mode } = useMeta();
  const { formatMessage } = useIntl();

  const sanitizedFellowship = meeting.fellowship || null;
  const fellowshipShortName = formatMessage({ id: `fellowshipShortName-${sanitizedFellowship}` });

  const [isLinkConfirmationModalOpen, toggleLinkConfirmationModal] = useToggle(false);

  const {
    formValues: flagFormStatus,
    updateFormFields: updateFlagFormStatus,
    resetForm: resetFlagFormStatus,
  } = useForm({
    type: mode,
    isActive: false,
  });

  const getFlagFormDefaultState = (flagFormType) => {
    // if (['admin', 'edit'].includes(flagFormType)) {
    if (flagFormType === 'edit') {
      return {
        ...meeting,
        userGeneratedNote: '',
      };
    }

    if (flagFormType === 'admin') {
      return {
        ...meeting,
        ...parentData.currentMeetingValues,
        userGeneratedNote: '',
      };
    }

    return {
      requestorEmail: '',
    };
  };

  const {
    formValues: flagForm,
    resetForm: resetFlagForm,
    resetFormField: resetFlagFormField,
    updateFormFields: updateFlagForm,
  } = useForm(getFlagFormDefaultState(mode));

  // parent functions allow a parent component to update MeetingItem props based on flagForm input
  const enhancedUpdateFlagForm = (newPairs) => {
    updateFlagForm(newPairs);
    updateParent(newPairs);
  };
  const enhancedResetFlagFormField = (fieldName) => {
    resetFlagFormField(fieldName);
    resetParentField(fieldName);
  };
  const enhancedResetFlagForm = (type) => {
    updateFlagForm(getFlagFormDefaultState(type));
  };

  const toggleMeetingHiddenState = () => enhancedUpdateFlagForm({
    isHidden: !meeting.isHidden,
  });

  const toggleFlagForm = (flagFormConfigs) => {
    if (flagFormStatus.isActive) { return resetFlagFormStatus(); }

    const { type = mode } = flagFormConfigs;
    updateFlagForm(getFlagFormDefaultState(type));

    return updateFlagFormStatus({
      type,
      isActive: true,
    });
  };

  // SupportTicket enhancers
  const setTicketClassName = get(parentData, ['setTicketClassName'], () => '');
  const ticketId = get(parentData, ['ticketId'], null);
  const ticketType = get(parentData, ['ticketType'], '');
  const toggleShowingTicket = get(parentData, ['toggleShowingTicket'], () => { });

  const {
    shouldShowToast,
    triggerToast,
    toastDuration,
    toastMessage,
    toastSentiment,
  } = useToast(2000);

  const getFooterStrategy = () => {
    if (mode === 'admin' || flagFormStatus.type === 'edit') { return 'admin'; }
    if (flagFormStatus.type === 'flag') { return 'flagForm'; }

    return 'default';
  };

  const shouldShowHiddenListingFields = mode === 'admin'
    || flagFormStatus.type === 'edit';

  return (
    <Fragment>
      <LinkConfirmationModal
        hasPassword={meeting.hasPassword}
        isModalOpen={isLinkConfirmationModalOpen}
        meetingId={meeting.id}
        meetingName={meeting.meetingName}
        meetingPassword={meeting.meetingPassword}
        toggleLinkConfirmationModal={toggleLinkConfirmationModal}
      />
      <div className="card" style={{ opacity: meeting.isHidden ? '50%' : '100%' }}>
        <MeetingItemHeader
          isFlagMode={flagFormStatus.type === 'flag'}
          ticketType={ticketType}
          flagForm={flagForm}
          currentMeetingValues={parentData.currentMeetingValues}
        />
        <div className="card-body pb-2">
          <div className="row no-gutters d-flex align-items-start mb-4">
            <div className="col-md-8 col-8">
              <h5 className="card-title mb-2">{meeting.meetingName}</h5>
              {meeting.geo && (<MeetingLocation geo={meeting.geo} />)}
            </div>
            <div className="col-md-4 col-4 d-flex justify-content-end">
              <MeetingItemActionButtons
                fellowship={fellowshipShortName}
                flagFormStatus={flagFormStatus}
                isHidden={meeting.isHidden}
                meetingId={meeting.id}
                meetingName={meeting.meetingName}
                onPencilClick={() => toggleFlagForm({ type: 'edit' })}
                toggleMeetingHiddenState={toggleMeetingHiddenState}
              />
            </div>
          </div>
          <MeetingInfo
            dayOfWeek={meeting.dayOfWeek}
            endTime={meeting.endTime}
            fellowship={meeting.fellowship}
            geo={meeting.geo}
            id={meeting.id}
            language={meeting.language}
            localStartTime={meeting.localStartTime}
            startTime={meeting.startTime}
            tags={meeting.tags}
          />
          {flagFormStatus.isActive && (
            <FlagForm
              flagForm={flagForm}
              isEditMode={flagFormStatus.type === 'edit'}
              resetFlagFormField={enhancedResetFlagFormField}
              updateFlagForm={enhancedUpdateFlagForm}
            />
          )}
          {shouldShowHiddenListingFields && (
            <HiddenListingFields
              currentMeetingValues={mode === 'admin' ? getFlagFormDefaultState('admin') : getFlagFormDefaultState('edit')}
              flagForm={flagForm}
              updateFlagForm={enhancedUpdateFlagForm}
            />
          )}
        </div>
        <MeetingItemToast
          shouldShowToast={shouldShowToast}
          toastSentiment={toastSentiment}
          toastDuration={toastDuration}
          toastMessage={toastMessage}
        />
        <div className="card-footer">
          <Switch condition={getFooterStrategy()}>
            <Case value="flagForm">
              <FlagFormFooter
                flagForm={flagForm}
                meetingId={meeting.id}
                mode={mode}
                resetFlagForm={resetFlagForm}
                toggleFlagMode={toggleFlagForm}
                triggerToast={triggerToast}
                updateFlagForm={updateFlagForm}
              />
            </Case>
            <Case value="admin">
              <SupportTicketFooter
                currentMeetingValues={getFlagFormDefaultState('admin')}
                flagForm={flagForm}
                isFlagMode={flagFormStatus.type === 'flag'}
                isHidden={meeting.isHidden}
                meetingId={meeting.id}
                resetFlagForm={() => enhancedResetFlagForm('admin')}
                resolveAllTickets={parentData.resolveAllTickets}
                setTicketClassName={setTicketClassName}
                ticketId={ticketId}
                toggleFlagMode={toggleFlagForm}
                toggleShowingTicket={toggleShowingTicket}
                triggerToast={triggerToast}
                updateFlagForm={updateFlagForm}
              />
            </Case>
            <Default>
              <MeetingItemFooter
                meetingName={meeting.meetingName}
                meetingId={meeting.id}
                toggleLinkConfirmationModal={toggleLinkConfirmationModal}
                onFlagClick={() => toggleFlagForm({ type: 'flag' })}
                onPencilClick={() => toggleFlagForm({ type: 'edit' })}
              />
            </Default>
          </Switch>
        </div>
      </div>
    </Fragment>
  );
};

MeetingItem.displayName = 'MeetingItem';

MeetingItem.propTypes = {
  meeting: meetingShape.isRequired,
  parentData: PropTypes.shape({
    currentMeetingValues: meetingShape,
    resolveAllTickets: PropTypes.func,
  }),
  resetParentField: PropTypes.func,
  updateParent: PropTypes.func,
};

MeetingItem.defaultProps = {
  parentData: {
    ticketId: null,
    currentMeetingValues: {},
  },
  resetParentField: () => {},
  updateParent: () => {},
};

export default MeetingItem;
