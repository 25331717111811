import React from 'react';
import PropTypes from 'prop-types';
import { connectAsync } from 'iguazu';
import { queryResource } from 'iguazu-rest';
import Helmet from 'react-helmet';
import { FormattedMessage, useIntl } from '../lib/ReactIntlAdapter';
import meetingShape from '../types/meetingShape';
import MeetingItem from '../components/meeting-item/MeetingItem';
import Layout from '../components/Layout';
import LocationBuilder from '../components/meeting-location/LocationBuilder';
import { formatHour } from '../utils/formatHour';

const MeetingByIdTemplate = ({
  isLoading,
  // location,
  loadedWithErrors,
  meeting,
}) => {
  const { formatMessage } = useIntl();

  if (isLoading()) return <p><FormattedMessage id="loading" /></p>;
  if (loadedWithErrors()) return <p><FormattedMessage id="error-loadingMeeting" /></p>;

  const {
    fellowship,
    meetingName,
    localStartTime,
    dayOfWeek,
    geo,
  } = meeting;

  const locationBuilder = new LocationBuilder(geo);
  const { locationLine1 } = locationBuilder.build();

  const formattedHour = formatHour(localStartTime);
  const dayOfWeekName = formatMessage({ id: `day-${dayOfWeek}-name` });

  const meetingDescription = `(${fellowship}) - ${locationLine1}, ${dayOfWeekName} ${formattedHour}`;

  return (
    <Layout>
      <Helmet
        description={meetingDescription}
        meta={[
          // { property: 'og:url', content: `https://12steps.video${location.pathname}` },
          { property: 'og:title', content: meetingName },
          { property: 'og:description', content: meetingDescription },
        ]}
        // url={`https://12steps.video${location.pathname}`}
        htmlAttributes={{
          prefix: 'og: http://ogp.me/ns#',
        }}
      >
        {/* <!-- Primary Meta Tags --> */}
        <title>{meetingName}</title>
        <meta name="title" content={meetingName} />
        <meta name="description" content={meetingDescription} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={meetingName} />
        <meta property="og:description" content={meetingDescription} />
        <meta property="og:image" content="" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:title" content={meetingName} />
        <meta property="twitter:description" content={meetingDescription} />
        <meta property="twitter:image" content="" />
        <script
          src="https://cdn.usefathom.com/3.js"
          site="TQOWDOCU"
        />
      </Helmet>
      <div className="row mt-4">
        <div className="offset-md-2" />
        <div className="col-md-8">
          <MeetingItem meeting={meeting} />
        </div>
      </div>
    </Layout>
  );
};

MeetingByIdTemplate.propTypes = {
  meeting: meetingShape.isRequired,
  isLoading: PropTypes.func.isRequired,
  loadedWithErrors: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    href: PropTypes.string,
  }).isRequired,
};

function loadDataAsProps({ store: { dispatch, getState }, ownProps: { id } }) {
  const { filters: { timeZone } } = getState();

  return {
    meeting: () => dispatch(queryResource({
      resource: 'meeting',
      id,
      opts: {
        query: { timeZone },
      },
    })),
  };
}

export default connectAsync({ loadDataAsProps })(MeetingByIdTemplate);
