import React, { useState } from 'react';
import { InputGroup, Input, InputGroupAddon } from 'reactstrap';
import { navigate } from '@reach/router';
import { useIntl, FormattedMessage } from '../../lib/ReactIntlAdapter';
import Button from '../../lib/Button';
import TinyText from '../../lib/TinyText';

const SearchBar = () => {
  const { formatMessage } = useIntl();
  const [searchQuery, setSearchQuery] = useState('');

  const searchForMeetings = () => {
    if (searchQuery) { navigate(`/search/${searchQuery}`); }
  };

  return (
    <div className="mt-2">
      <InputGroup>
        <Input
          placeholder={formatMessage({ id: 'searchBar-placeholder' })}
          onChange={({ target: { value } }) => setSearchQuery(value)}
          onKeyUp={({ key }) => { if (key === 'Enter') { searchForMeetings(); } }}
        />
        <InputGroupAddon addonType="append">
          <Button className="btn-tertiary border" onClick={searchForMeetings}>
            <TinyText className="mb-0">
              <FormattedMessage id="searchBar-searchButton-label" />
            </TinyText>
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};

SearchBar.displayName = 'SearchBar';

SearchBar.propTypes = {

};

export default SearchBar;
