import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { partition, sortBy } from 'lodash';
import { useIntl } from '../ReactIntlAdapter';
import { useMeta } from '../../hooks/useMeta';
import Dropdown from '../Dropdown';

const FellowshipDropdown = ({
  className,
  currentValue,
  onChange,
  showAllFellowshipsOption,
}) => {
  const { formatMessage } = useIntl();
  const { fellowships } = useMeta();
  const {
    activeFilters: { fellowship: selectedFellowship },
  } = useSelector((state) => state.filters);

  const defaultFellowship = currentValue || selectedFellowship;

  const fellowshipOptions = sortBy(fellowships, ({ name }) => name).map(({ name }) => ({
    id: name,
    label: formatMessage({ id: `fellowshipTitle-${name}` }),
    value: name,
  }));

  const sortedFellowshipOptions = sortBy(fellowshipOptions, ({ label }) => label);

  const allFellowshipsOption = {
    id: 'all',
    value: 'all',
    label: formatMessage({ id: 'filter-all-fellowships' }),
  };

  const getFellowshipOptionsWithDefaultFellowshipFirst = () => {
    const [
      defaultOption,
      otherOptions,
    ] = partition(sortedFellowshipOptions, ({ value }) => value === defaultFellowship);

    return [...defaultOption, allFellowshipsOption, ...otherOptions];
  };

  const combinedOptions = selectedFellowship === 'all'
    ? [allFellowshipsOption, ...sortedFellowshipOptions]
    : getFellowshipOptionsWithDefaultFellowshipFirst();

  const optionsToShow = showAllFellowshipsOption
    ? combinedOptions
    : fellowshipOptions;

  return (
    <Dropdown
      buttonClass={`btn-outline-secondary ${className}`}
      label={formatMessage({ id: 'dropdownLabel-fellowship' })}
      name="fellowship"
      onChange={onChange}
      options={optionsToShow}
    />
  );
};

FellowshipDropdown.displayName = 'FellowshipDropdown';

FellowshipDropdown.propTypes = {
  className: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showAllFellowshipsOption: PropTypes.bool,
};

FellowshipDropdown.defaultProps = {
  className: '',
  currentValue: '',
  showAllFellowshipsOption: false,
};

export default FellowshipDropdown;
