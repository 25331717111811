/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useMenuPortalContext } from '../../hooks/useMenuPortalContext';

// This component exists because other elements on the page will
// overlap the default Select menu options without these settings
const EnhancedSelect = ({
  handleInput,
  isMulti,
  name,
  ...otherProps
}) => {
  const customStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 1000000,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '.75em',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '.9em',
    }),
  };

  const [target, setTarget] = useState(null);
  const portalContext = useMenuPortalContext();
  const isSticky = portalContext && portalContext.isSticky;

  useEffect(() => {
    if (!isSticky) {
      setTarget(document.body);
    }
  }, [isSticky, setTarget]);

  const handleSingularInput = ({ value }) => handleInput(name, value);
  const handlePluralInput = (selectedOptions) => {
    const sanitizedOptions = !!selectedOptions && selectedOptions.length > 0
      ? selectedOptions.map(({ value }) => value)
      : [];
    handleInput(name, sanitizedOptions);
  };

  const onChange = isMulti
    ? handlePluralInput
    : handleSingularInput;

  return (
    <Select
      isMulti={isMulti}
      menuPortalTarget={isSticky ? undefined : target}
      onChange={onChange}
      styles={customStyles}
      {...otherProps}
    />
  );
};

EnhancedSelect.displayName = 'EnhancedSelect';

EnhancedSelect.propTypes = {
  isMulti: PropTypes.bool,
  handleInput: PropTypes.func,
  name: PropTypes.string.isRequired,
};

EnhancedSelect.defaultProps = {
  handleInput: () => {},
  isMulti: false,
};

export default EnhancedSelect;
