import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from '../../lib/ReactIntlAdapter';
import MobileSafeDropdown from '../../lib/inputs/MobileSafeDropdown';
import DayOfWeekDropdown from '../../lib/dropdowns/DayOfWeekDropdown';
import { setAdminFilters } from '../../redux/reducers/admin';

const SupportTicketFilters = ({ ticketCount }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const {
    filters: {
      dayOfWeek,
      ticketType,
      visibility,
    },
  } = useSelector((state) => state.admin);

  const visibilityOptions = [
    {
      id: 'visible',
      label: 'Visible Meetings',
      value: 'visible',
    },
    {
      id: 'hidden',
      label: 'Hidden Meetings',
      value: 'hidden',
    },
  ];

  const ticketTypes = [
    {
      id: 'newMeetings',
      label: formatMessage({ id: 'adminPanel-tab-newMeetings-label' }),
      value: 'newMeetings',
    },
    {
      id: 'flaggedMeetings',
      label: formatMessage({ id: 'adminPanel-tab-flaggedMeetings-label' }),
      value: 'flaggedMeetings',
    },
    {
      id: 'changeRequests',
      label: 'Change Requests',
      value: 'changeRequests',
    },
  ];

  const handleDayOfWeekInput = ({ target: { value } }) => dispatch(setAdminFilters({
    dayOfWeek: Number(value),
  }));

  return (
    <div className="card">
      <div className="card-body">
        <div className="row mb-2">
          <div className="col-12">
            <h6 className="mt-2 ml-1">
              <FormattedMessage id="Support Ticket Filters" />
            </h6>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <MobileSafeDropdown
              currentValue={ticketTypes.find(({ id }) => id === ticketType)}
              handleInput={(name, value) => dispatch(setAdminFilters({ [name]: value }))}
              name="ticketType"
              placeholder="Ticket Type"
              options={ticketTypes}
            />
          </div>
        </div>
        {ticketType === 'flaggedMeetings' && (
          <Fragment>
            <div className="row mb-2">
              <div className="col-12">
                <MobileSafeDropdown
                  currentValue={visibilityOptions.find(({ id }) => id === visibility)}
                  handleInput={(name, value) => dispatch(setAdminFilters({ [name]: value }))}
                  name="visibility"
                  placeholder="Visibility Status"
                  options={visibilityOptions}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12">
                <DayOfWeekDropdown currentValue={dayOfWeek} onChange={handleDayOfWeekInput} />
              </div>
            </div>
          </Fragment>
        )}
        {ticketCount !== null && (
          <div className="row no-gutters">
            <div className="col-12">
              <p className="text-center">
                <small>
                  <em>{ticketCount} meetings match these criteria</em>
                </small>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SupportTicketFilters.displayName = 'SupportTicketFilters';

SupportTicketFilters.propTypes = {
  ticketCount: PropTypes.number,
};

SupportTicketFilters.defaultProps = {
  ticketCount: null,
};

export default SupportTicketFilters;
