/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../lib/ReactAuthStub';
import {
  clearCollection,
  createResource,
  getResource,
  loadCollection,
  updateResource,
} from 'iguazu-rest';
import { Spinner } from 'reactstrap';
import { get, isEqual, pick } from 'lodash';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import Button from '../../lib/Button';
import TinyText from '../../lib/TinyText';
import CloudUpload from '../../lib/icons/CloudUpload';
import Pencil from '../../lib/icons/Pencil';
import Trash from '../../lib/icons/Trash';
import flagFormShape from '../../types/flagFormShape';
import meetingShape from '../../types/meetingShape';
import { useMeta } from '../../hooks/useMeta';

const SupportTicketFooter = ({
  currentMeetingValues,
  flagForm,
  meetingId,
  resolveAllTickets,
  ticketId,
  toggleFlagMode,
}) => {
  const { mode } = useMeta();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [isMeetingUpdating, setIsMeetingUpdating] = useState(false);
  const [isTicketResolutionPending, setTicketResolutionPending] = useState(false);

  const flagFormType = mode === 'admin'
    ? 'admin'
    : 'edit';

  const fieldsToUpdate = Object.keys(flagForm)
    .filter((flagFormKey) => !isEqual(flagForm[flagFormKey], currentMeetingValues[flagFormKey]));

  const globalState = useSelector((state) => state);
  const { id: userId } = getResource({
    resource: 'users',
    id: get(user, 'sub', -1),
  })(globalState);

  const getNoteActionType = () => {
    const isVisibilityChange = fieldsToUpdate.includes('isHidden');

    if (isVisibilityChange) {
      return flagForm.isHidden ? 'HIDE_MEETING' : 'UNHIDE_MEETING';
    }

    return 'COMMENT';
  };

  const updateMeeting = async () => {
    setIsMeetingUpdating(true);

    const newMeetingValues = pick(flagForm, fieldsToUpdate);

    const nonMeetingItemFieldsToUpdate = ['userGeneratedNote', 'requestorEmail'];
    const meetingItemFieldsToUpdate = fieldsToUpdate
      .filter((fieldName) => !nonMeetingItemFieldsToUpdate.includes(fieldName));

    const { userGeneratedNote } = flagForm;

    if (userGeneratedNote) {
      await dispatch(createResource({
        resource: 'notes',
        id: meetingId,
        opts: {
          body: JSON.stringify({
            actionType: getNoteActionType(),
            note: userGeneratedNote,
            createdById: userId || Number(process.env.GATSBY_ADMIN_ID),
          }),
        },
      }));
    }

    if (meetingItemFieldsToUpdate.length > 0) {
      await dispatch(updateResource({
        resource: 'meetings',
        id: {
          id: meetingId,
          newMeetingValues,
        },
        opts: {
          body: JSON.stringify({
            meetingId,
            ...newMeetingValues,
          }),
        },
      }))
        .then(() => {
          const systemGeneratedNotes = meetingItemFieldsToUpdate.map((fieldName) => {
            const oldValue = currentMeetingValues[fieldName] || '[empty]';
            const newValue = newMeetingValues[fieldName] || '[empty]';

            return `${fieldName}: ${oldValue} => ${newValue}`;
          });

          systemGeneratedNotes.map((note) => dispatch(createResource({
            resource: 'notes',
            id: meetingId,
            opts: {
              body: JSON.stringify({
                actionType: 'CHANGE',
                note,
                createdById: 14,
              }),
            },
          })));
        });
    }

    setIsMeetingUpdating(false);
    dispatch(clearCollection({
      resource: 'support',
      id: undefined,
    }));
    dispatch(loadCollection({
      resource: 'support',
      id: undefined,
      forceFetch: true,
    }));
  };

  const deleteTicket = () => {
    setTicketResolutionPending(true);
    resolveAllTickets();
  };

  return (
    <div className="form-row align-items-center animated fadeInDown">
      <div className="offset-xl-7 offset-lg-6 offset-md-5" />
      <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 d-flex justify-content-end">
        {fieldsToUpdate.length > 0 && (
          <Button
            className="btn-tertiary btn-sm text-dark p-0  mr-4"
            onClick={updateMeeting}
          >
            {isMeetingUpdating ? (
              <Spinner type="grow" color="secondary" size="sm" />
            ) : (
              <CloudUpload size="1em" />
            )}
            <TinyText className="text-dark d-inline-block ml-2">
              <FormattedMessage id="supportTicket-footer-buttonLabel-saveEdits" />
            </TinyText>
          </Button>
        )}
        {ticketId ? (
          <Button
            className="btn-tertiary btn-sm text-danger p-0"
            onClick={deleteTicket}
          >
            {isTicketResolutionPending ? (
              <Spinner type="grow" color="danger" size="sm" />
            ) : (
              <Trash size="1em" />
            )}
            <TinyText className="text-dark d-inline-block ml-2">
              <FormattedMessage id="supportTicket-footer-buttonLabel-resolveTicket" />
            </TinyText>
          </Button>
        ) : (
          <Button
            className="btn-tertiary btn-sm text-danger p-0"
            onClick={() => toggleFlagMode({ type: flagFormType })}
          >
            <Pencil size="1em" />
            <TinyText className="text-dark d-inline-block ml-2">
              <FormattedMessage id="supportTicket-footer-buttonLabel-saveEdits" />
            </TinyText>
          </Button>
        )}
      </div>
    </div>
  );
};

SupportTicketFooter.displayName = 'SupportTicketFooter';

SupportTicketFooter.propTypes = {
  currentMeetingValues: meetingShape.isRequired,
  flagForm: flagFormShape.isRequired,
  meetingId: PropTypes.number.isRequired,
  resolveAllTickets: PropTypes.func.isRequired,
  ticketId: PropTypes.number,
  toggleFlagMode: PropTypes.func.isRequired,
};

SupportTicketFooter.defaultProps = {
  ticketId: null,
};

export default SupportTicketFooter;
