import React from 'react';

const SectionDivider = () => (
  <div className="row mb-4">
    <div className="offset-md-2" />
    <div className="col-md-8">
      <hr />
    </div>
  </div>
);

SectionDivider.displayName = 'SectionDivider';

export default SectionDivider;
