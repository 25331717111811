import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import { useMeta } from '../../hooks/useMeta';
import Filters from '../meeting-filters/Filters';
import SupportTicketFilters from '../admin-panel/SupportTicketFilters';
import { toggleFilterModal } from '../../redux/reducers/filters';

const FilterModal = () => {
  const dispatch = useDispatch();
  const { isFilterModalOpen } = useSelector((state) => state.filters);
  const { mode } = useMeta();

  const closeModal = () => dispatch(toggleFilterModal());

  return (
    <Modal closeModal={closeModal} isOpen={isFilterModalOpen}>
      <ModalHeader toggle={closeModal}>
        <FormattedMessage id="meetingFilters-modal-header" />
      </ModalHeader>
      <ModalBody>
        {mode === 'admin' ? (
          <SupportTicketFilters />
        ) : (
          <Filters />
        )}
      </ModalBody>
    </Modal>
  );
};

FilterModal.displayName = 'FilterModal';

export default FilterModal;
