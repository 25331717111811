import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../lib/ReactAuthStub';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import Button from '../../lib/Button';
import TinyText from '../../lib/TinyText';
import Flag from '../../lib/icons/Flag';
import Pencil from '../../lib/icons/Pencil';

const MeetingItemFooter = ({
  toggleLinkConfirmationModal,
  meetingName,
  meetingId,
  onFlagClick,
  onPencilClick,
}) => {
  const slug = meetingName.toLowerCase().replace(/\s+/g, '-');
  const redirectToVideo = `https://steps-video-93623.appspot.com/api/v1/meetings/redirect/${slug}/${meetingId}`;

  const { isAuthorized } = useAuth();
  const loggedIn = isAuthorized('SUPER_ADMIN');

  return (
    <div className="row d-flex align-items-center">
      <div className="col-7">
        <a
          onClick={toggleLinkConfirmationModal}
          href={redirectToVideo}
          className="btn-link card-link"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="linkLabel-videoMeetingLink" />
        </a>
      </div>
      <div className="col-5">
        <Button
          className="btn-tertiary btn-sm text-danger p-0 float-right"
          onClick={loggedIn ? onPencilClick : onFlagClick}
        >
          {loggedIn ? (
            <span className="text-success p-0">
              <Pencil size="1em" />
              <TinyText className="text-dark d-inline-block ml-2">edit meeting</TinyText>
            </span>
          ) : (
            <Fragment>
              <Flag size="1em" />
              <TinyText className="text-dark d-inline-block ml-2">report an issue</TinyText>
            </Fragment>
          )}
        </Button>
      </div>
    </div>
  );
};

MeetingItemFooter.displayName = 'MeetingItemFooter';

MeetingItemFooter.propTypes = {
  meetingId: PropTypes.number.isRequired,
  meetingName: PropTypes.string.isRequired,
  onFlagClick: PropTypes.func.isRequired,
  onPencilClick: PropTypes.func.isRequired,
  toggleLinkConfirmationModal: PropTypes.func.isRequired,
};

export default MeetingItemFooter;
