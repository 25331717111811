export const addHoursToMilitaryTime = (militaryTime, militaryHours) => {
  const additionalHours = militaryHours >= 100
    ? Math.floor(militaryHours / 100)
    : 0;

  const minutesFromMilitaryTime = Number(militaryTime.toString().slice(-2));
  const additionalMinutes = militaryHours - (additionalHours * 100);

  const combinedAdditionalMinutes = minutesFromMilitaryTime + additionalMinutes;
  const additionalHourBasedOnMinutes = combinedAdditionalMinutes > 60
    ? 1
    : 0;

  const resultMinute = additionalHourBasedOnMinutes
    ? combinedAdditionalMinutes - 60
    : combinedAdditionalMinutes;

  const combinedAdditionalHours = additionalHours + additionalHourBasedOnMinutes;
  const resultHour = combinedAdditionalHours * 100;

  return resultHour + resultMinute;
};

export const convertMinutesToHours = (numberOfMinutes) => {
  const numberOfHours = numberOfMinutes > 60
    ? Math.floor(numberOfMinutes / 60)
    : 0;

  const numberOfMinutesWithoutHours = numberOfMinutes > 60
    ? numberOfMinutes - (numberOfHours * 60)
    : numberOfMinutes;

  const numberOfHoursInMilitaryTime = numberOfHours * 100;

  return numberOfHoursInMilitaryTime + numberOfMinutesWithoutHours;
};

export const sanitizeTime = (militaryTime) => {
  if (militaryTime < 100) { return 1200 + militaryTime; }
  if (militaryTime > 1259) { return militaryTime - 1200; }
  return militaryTime;
};

export const stringifyTime = (sanitizedTime) => {
  const digitArray = sanitizedTime.toString().split('');
  digitArray.splice(digitArray.length - 2, 0, ':');
  const timeStringWithColon = digitArray.join('');
  return timeStringWithColon;
};

export const formatHour = (militaryTime = 0) => {
  const timeOfDay = militaryTime > 1159 ? 'pm' : 'am';
  const sanitizedTime = sanitizeTime(militaryTime);
  const stringifiedTime = stringifyTime(sanitizedTime);
  return stringifiedTime + timeOfDay;
};

export const convertToMilitaryTime = (hour, minute, meridian) => {
  const sanitizedHour = Number(hour) === 12
    ? '00'
    : hour;

  const militaryHour = meridian === 'am'
    ? sanitizedHour
    : Number(sanitizedHour) + 12;

  return militaryHour + minute;
};
