import React from 'react';
import PropTypes from 'prop-types';
import { get, sortBy } from 'lodash';
import { useIntl } from '../ReactIntlAdapter';
import { useMeta } from '../../hooks/useMeta';
import tagShape from '../../types/tagShape';
import EnhancedSelect from './EnhancedSelect';

const TagsInput = ({
  fellowship,
  onChange,
  selectedTags,
}) => {
  const { formatMessage } = useIntl();
  const { fellowships } = useMeta();

  const sanitizedSelectedTags = selectedTags || [];
  const selectedTagsWithLabel = sanitizedSelectedTags.map(({ tagType }) => ({
    id: tagType,
    value: tagType,
    tagType,
    label: formatMessage({ id: `meetingTag-${fellowship}-${tagType}` }),
  }));

  const handleTagSelection = (newTagsArray) => onChange({
    target: {
      name: 'tags',
      value: newTagsArray,
    },
  });

  const buildSingularFellowshipTagOptions = () => {
    const fellowshipItem = fellowships.find(({ name }) => name === fellowship);
    const sanitizedTags = get(fellowshipItem, 'tags', []) || [];
    const tagsWithLabels = fellowshipItem
      ? sanitizedTags.map(({ tagType }) => ({
        id: tagType,
        label: formatMessage({ id: `meetingTag-${fellowship}-${tagType}` }),
        value: tagType,
        tagType,
      }))
      : [{ id: '', value: '', label: formatMessage({ id: 'meetingTag-error-noFellowshipSelected' }) }];

    const sortedTagsWithLabels = sortBy(tagsWithLabels, ['label']);

    const allTagsOption = {
      id: '',
      value: '',
      label: formatMessage({ id: 'meetingTag-noTagSelected' }),
    };

    return [allTagsOption, ...sortedTagsWithLabels];
  };

  return (
    <EnhancedSelect
      onChange={handleTagSelection}
      isMulti={true}
      name="fellowships"
      options={buildSingularFellowshipTagOptions()}
      placeholder={formatMessage({ id: 'dropdownLabel-tags' })}
      value={selectedTagsWithLabel}
    />
  );
};

TagsInput.displayName = 'TagsInput';

TagsInput.propTypes = {
  fellowship: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedTags: PropTypes.arrayOf(tagShape),
};

TagsInput.defaultProps = {
  selectedTags: [],
};

export default TagsInput;
