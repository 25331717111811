import React from 'react';
import PropTypes from 'prop-types';

export const useAuth = () => ({
  user: {},
  isAuthorized: () => false,
  isAuthenticated: () => false,
  login: console.log,
  logout: console.log,
});

export const AuthProvider = ({ children }) => <div>{children}</div>;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
