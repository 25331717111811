import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { partition, sortBy } from 'lodash';
import { useIntl } from '../ReactIntlAdapter';
import Dropdown from '../Dropdown';
import { useMeta } from '../../hooks/useMeta';

const TagDropdown = ({
  className,
  fellowship,
  onChange,
}) => {
  const { fellowships } = useMeta();
  const { formatMessage } = useIntl();
  const {
    activeFilters: {
      tags: selectedTag,
    },
  } = useSelector((state) => state.filters);

  const myFellowshipItem = fellowships.find(({ name }) => name === fellowship)
    || { tags: [] };
  const { tags: myFellowshipTags } = myFellowshipItem;
  const tagsWithLabels = myFellowshipTags.map(({ tagType }) => ({
    id: tagType,
    value: tagType,
    label: formatMessage({ id: `meetingTag-${fellowship}-${tagType}` }),
  }));
  const sortedTagOptions = sortBy(tagsWithLabels, ({ label }) => label);

  const [
    myTagOption,
    otherTagOptions,
  ] = partition(sortedTagOptions, ({ value }) => value === selectedTag);

  const allTagsOption = {
    id: '',
    value: '',
    label: formatMessage({ id: 'meetingTag-noTagSelected' }),
  };

  const tagOptionsToShow = selectedTag
    ? [...myTagOption, allTagsOption, ...otherTagOptions]
    : [allTagsOption, ...sortedTagOptions];

  return (
    <Dropdown
      buttonClass={`btn-outline-secondary ${className}`}
      label={formatMessage({ id: 'dropdownLabel-all-tags' })}
      name="tags"
      onChange={onChange}
      options={tagOptionsToShow}
    />
  );
};

TagDropdown.displayName = 'TagDropdown';

TagDropdown.propTypes = {
  className: PropTypes.string,
  fellowship: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TagDropdown.defaultProps = {
  className: '',
  fellowship: '',
};

export default TagDropdown;
