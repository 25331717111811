import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { remove } from 'lodash';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import { useMeta } from '../../hooks/useMeta';
import FlagFormRow from './FlagFormRow';
import flagFormShape from '../../types/flagFormShape';
import DefaultFlagFormValidator from './FlagFormValidator';
import SupportTicketValidator from '../admin-panel/SupportTicketValidator';
import SectionDivider from '../../lib/SectionDivider';

const FlagForm = ({
  flagForm,
  isEditMode,
  resetFlagFormField,
  updateFlagForm,
}) => {
  const { mode } = useMeta();
  const flagFormValidator = mode === 'admin' || isEditMode
    ? new SupportTicketValidator(flagForm)
    : new DefaultFlagFormValidator(flagForm);

  const [uniqueRowKeys, setUniqueRowKeys] = useState([Math.random() * 100]);
  const [formRows, setFormRows] = useState([{ id: 0 }]);

  const calculateNewUniqueKey = () => {
    let unusedNumber = Math.random() * 100;

    while (uniqueRowKeys.includes(unusedNumber)) {
      unusedNumber = Math.random() * 100;
    }

    setUniqueRowKeys([...uniqueRowKeys, unusedNumber]);
    return unusedNumber;
  };

  const addRow = () => {
    const newRowId = calculateNewUniqueKey();
    const newFormRows = [...formRows, { id: newRowId }];
    setFormRows(newFormRows);
  };

  const deleteRow = (myRowId, myFieldName) => {
    const newFormRows = [...formRows];
    remove(newFormRows, { id: myRowId });

    resetFlagFormField(myFieldName);

    setFormRows(newFormRows);
  };

  const setFieldName = (rowId, value, lastFieldName) => {
    const myRowIndex = formRows.findIndex(({ id }) => id === rowId);
    const newFormRows = [...formRows];
    newFormRows[myRowIndex].fieldName = value;

    const isBooleanFieldType = flagFormValidator.isBooleanFieldType(value);

    const newFlagFormValues = {};
    if (isBooleanFieldType) { newFlagFormValues[value] = 'true'; }
    if (isBooleanFieldType && lastFieldName) { newFlagFormValues[lastFieldName] = null; }

    if (mode === 'default') { updateFlagForm(newFlagFormValues); }

    setFormRows(newFormRows);
  };

  return (
    <div className="animated fadeInLeft mt-3 mb-4">
      <SectionDivider />
      <div className="row">
        <div className="col-md-12">
          <h6 className="ml-1 mb-2">
            <FormattedMessage id={`flagForm-header-prompt-${flagFormValidator.mode}`} />
          </h6>
          <form>
            <div className="mt-4">
              {formRows.map((rowConfig, index) => (
                <FlagFormRow
                  addRow={addRow}
                  deleteRow={deleteRow}
                  fieldName={rowConfig.fieldName}
                  formLength={formRows.length}
                  formRows={formRows}
                  flagForm={flagForm}
                  flagFormValidator={flagFormValidator}
                  isEditMode={isEditMode}
                  key={`flagFormRow-${`${rowConfig.fieldName}-${rowConfig.id}` || rowConfig.id}`}
                  rowId={rowConfig.id}
                  rowIndex={index}
                  setFieldName={setFieldName}
                  updateFlagForm={updateFlagForm}
                />
              ))}
            </div>
          </form>
          {flagFormValidator.hasEmailRequiredFields() && (
            <p className="mt-md-4 mt-2 text-center animated fadeInDown">
              <small>
                <em>
                  <FormattedMessage id="flagForm-emailRequired-explainer" />
                </em>
              </small>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

FlagForm.displayName = 'FlagForm';

FlagForm.propTypes = {
  flagForm: flagFormShape.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  resetFlagFormField: PropTypes.func.isRequired,
  updateFlagForm: PropTypes.func.isRequired,
};

export default FlagForm;
