import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '../../lib/Button';
import TinyText from '../../lib/TinyText';
import Envelope from '../../lib/icons/Envelope';
import HouseDoor from '../../lib/icons/HouseDoor';
import PlusCircle from '../../lib/icons/PlusCircle';
import Star from '../../lib/icons/Star';
import { activateFeedbackModal } from '../../redux/reducers/feedback';

const TopLevelNavButtons = ({ textClass }) => {
  const dispatch = useDispatch();
  const toggleFeedbackModal = () => dispatch(activateFeedbackModal());

  return (
    <Fragment>
      <a
        className={`btn btn-tertiary mb-0 pb-0 ${textClass}`}
        href="/"
      >
        <HouseDoor size="1.25em" />
        <TinyText>Home</TinyText>
      </a>
      <a
        className={`btn btn-tertiary mb-0 pb-0 ${textClass}`}
        href="/favorites"
      >
        <Star size="1.25em" />
        <TinyText>Favorites</TinyText>
      </a>
      <a
        className={`btn btn-tertiary mb-0 pb-0 ${textClass}`}
        href="/new"
      >
        <PlusCircle size="1.25em" />
        <TinyText>Add Meeting</TinyText>
      </a>
      <Button
        className={`btn btn-tertiary mb-0 pb-0 ${textClass}`}
        onClick={toggleFeedbackModal}
      >
        <Envelope size="1.25em" />
        <TinyText>Contact Us</TinyText>
      </Button>
    </Fragment>
  );
};

TopLevelNavButtons.displayName = 'TopLevelNavButtons';

TopLevelNavButtons.propTypes = {
  textClass: PropTypes.string,
};

TopLevelNavButtons.defaultProps = {
  textClass: 'text-black-50',
};

export default TopLevelNavButtons;
