import React from 'react';
import PropTypes from 'prop-types';
import { pull, sortBy } from 'lodash';
import { useIntl } from '../ReactIntlAdapter';
import Dropdown from '../Dropdown';
import languageNames from '../../configs/languageNames.json';

const LanguageDropdown = ({
  className,
  onChange,
}) => {
  const { formatMessage, locale } = useIntl();

  const languageCodes = Object.keys(languageNames);
  pull(languageCodes, locale);

  const languageNamesInMyLanguage = languageCodes
    .map((languageCode) => formatMessage({
      id: `languageName-${languageCode}`,
    }));

  const pairedLanguageNames = languageCodes
    .map((languageCode, index) => `${languageNamesInMyLanguage[index]} - ${languageNames[languageCode]}`
    );

  const allOptions = languageCodes.map((languageCode, index) => ({
    label: pairedLanguageNames[index],
    value: languageCode,
    id: languageCode,
  }));

  const sortedOptions = sortBy(allOptions, ({ label }) => label);

  const unsetOption = {
    label: formatMessage({ id: `languageName-${locale}` }),
    value: locale,
    id: locale,
  };

  const languageOptions = [unsetOption, ...sortedOptions];

  return (
    <Dropdown
      buttonClass={`btn-outline-secondary ${className}`}
      label={formatMessage({ id: 'dropdownLabel-language' })}
      name="language"
      onChange={onChange}
      options={languageOptions}
    />
  );
};

LanguageDropdown.displayName = 'LanguageDropdown';

LanguageDropdown.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

LanguageDropdown.defaultProps = {
  className: '',
};

export default LanguageDropdown;
