import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from '../../lib/ReactIntlAdapter';
import HappyBrowser from '../../lib/svgArt/HappyBrowser.svg';

const SuccessExperience = ({ resetModal }) => {
  const { formatMessage } = useIntl();

  setTimeout(resetModal, 1500);

  return (
    <div className="row">
      <div className="offset-3" />
      <div className="col-6">
        <img
          alt={formatMessage({ id: 'linkConfirmation-successMessage' })}
          className="p-5 mw-100 mh-50 animated fadeIn"
          src={HappyBrowser}
        />
        <p className="text-center">
          <FormattedMessage id="linkConfirmation-successMessage" />
        </p>
      </div>
    </div>
  );
};

SuccessExperience.displayName = 'SuccessExperience';

SuccessExperience.propTypes = {
  resetModal: PropTypes.func.isRequired,
};

export default SuccessExperience;
