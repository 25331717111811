import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Select = styled.select`
  height: 2.5em;
  max-width: 100%;
`;

const Dropdown = ({
  buttonClass,
  isDisabled,
  label,
  name,
  onBlur,
  onChange,
  options,
  value,
}) => {
  const assembledButtonClassname = `btn ${buttonClass} w-100`;

  return (
    <Select
      className={assembledButtonClassname}
      defaultValue={value}
      disabled={isDisabled}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
    >
      {options.map(({ id, label: optionLabel, selected }) => (
        <option value={id} key={`${label}-option-${id}`} selected={selected}>
          {optionLabel}
        </option>
      ))}
    </Select>
  );
};

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  buttonClass: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })).isRequired,
  value: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  }),
};

Dropdown.defaultProps = {
  buttonClass: '',
  isDisabled: false,
  onBlur: () => {},
  value: null,
};

export default Dropdown;
