import { flatten, get, merge } from 'lodash';
import adminConfigs from '../admin-panel/SupportTicket.configs.json';
import defaultConfigs from './FlagForm.configs.json';

export default class UiStrategies {
  constructor(mode) {
    this.configs = mode === 'admin'
      ? adminConfigs
      : defaultConfigs;

    this.strategyTypes = this.getStrategyTypes();
    this.fieldStrategies = this.mapFieldStrategies();
  }

  getStrategyTypes() {
    const allStrategies = get(this.configs, 'uiStrategies', []);
    return Object.keys(allStrategies);
  }

  mapFieldStrategies() {
    const fieldArray = flatten(this.strategyTypes.map((strategyType) => {
      const strategyFields = this.configs.uiStrategies[strategyType];
      return flatten(strategyFields.map((strategyField) => ({ [strategyField]: strategyType })));
    }));

    return merge({}, ...fieldArray);
  }

  getStrategy(fieldName) {
    return this.fieldStrategies[fieldName];
  }
}
