import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { createResource } from 'iguazu-rest';
import { FormattedMessage, useIntl } from '../../lib/ReactIntlAdapter';
import { closeFeedbackModal } from '../../redux/reducers/feedback';
import useForm from '../../hooks/useForm';
import useToast from '../../hooks/useToast';

const GeneralFeedbackModal = () => {
  const { formatMessage } = useIntl();
  const { isModalOpen } = useSelector((state) => state.feedback);

  const { formValues, updateFormFields } = useForm({
    requestorEmail: '',
    message: '',
  });

  const dispatch = useDispatch();

  const {
    shouldShowToast,
    triggerToast,
    toastMessage,
    toastSentiment,
  } = useToast();

  const toggleModal = () => dispatch(closeFeedbackModal());

  const handleInput = ({ target: { name, value } }) => updateFormFields({ [name]: value });

  const isValidEmail = (email) => email.match(/\S*@\S*\.[A-z].*/g);

  const handleSubmit = () => {
    if (!isValidEmail(formValues.requestorEmail) && formValues.message.length < 5) {
      return triggerToast({
        sentiment: 'danger',
        message: <FormattedMessage id="feedbackModal-onSubmitToast-error-bothInvalid" />,
      });
    }
    if (!isValidEmail(formValues.requestorEmail) && formValues.message.length > 5) {
      return triggerToast({
        sentiment: 'danger',
        message: <FormattedMessage id="feedbackModal-onSubmitToast-error-invalidEmail" />,
      });
    }
    if (isValidEmail(formValues.requestorEmail) && formValues.message.length < 5) {
      return triggerToast({
        sentiment: 'danger',
        message: <FormattedMessage id="feedbackModal-onSubmitToast-error-invalidMessage" />,
      });
    }

    return dispatch(createResource({
      resource: 'contact',
      id: formValues,
      opts: {
        body: JSON.stringify(formValues),
      },
    }))
      .then(() => {
        triggerToast({
          sentiment: 'success',
          message: <FormattedMessage id="feedbackModal-onSubmitToast-success" />,
        });
        setTimeout(toggleModal, 2500);
      })
      .catch(() => {
        triggerToast({
          sentiment: 'danger',
          message: <FormattedMessage id="feedbackModal-onSubmitToast-error-generic" />,
        });
        setTimeout(toggleModal, 2500);
      });
  };

  return (
    <Fragment>
      <Modal size="lg" isOpen={isModalOpen} toggle={(toggleModal)}>
        <ModalHeader toggle={(toggleModal)}>
          <FormattedMessage id="feedbackModal-header-contactUs" />
        </ModalHeader>
        <ModalBody>
          <h5 className="text-center">
            <FormattedMessage id="feedbackModal-content-description" />
          </h5>
          <div className="callout callout-warning">
            <FormattedMessage id="feedbackModal-content-callout" />
          </div>
          <Form>
            <FormGroup row={true}>
              <div className="offset-sm-1" />
              <div className="col-sm-2">
                <label htmlFor="email">
                  <FormattedMessage id="feedbackModal-content-email-label" />
                </label>
              </div>
              <div className="col-sm-8">
                <Input
                  name="requestorEmail"
                  onChange={handleInput}
                  placeholder={formatMessage({ id: 'feedbackModal-inputPlaceholder-requestorEmail' })}
                  type="email"
                  value={formValues.requestorEmail || ''}
                />
              </div>
            </FormGroup>
            <FormGroup row={true}>
              <div className="offset-sm-1" />
              <div className="col-sm-2">
                <label htmlFor="message">
                  <FormattedMessage id="feedbackModal-content-message-label" />
                </label>
              </div>
              <div className="col-md-8">
                <Input
                  name="message"
                  onChange={handleInput}
                  placeholder={formatMessage({ id: 'feedbackModal-inputPlaceholder-message' })}
                  type="textarea"
                  value={formValues.message || ''}
                />
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Alert color={toastSentiment} isOpen={shouldShowToast}>
            {toastMessage}
          </Alert>
          <Button color="primary" onClick={handleSubmit} className="mr-2">
            <FormattedMessage id="feedbackModal-content-submit-button" />
          </Button>
          <Button color="secondary" onClick={(toggleModal)}>
            <FormattedMessage id="feedbackModal-content-cancel-button" />
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default GeneralFeedbackModal;
