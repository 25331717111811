import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Switch, { Case, Default } from 'react-switch-case';
import { useIntl } from '../../lib/ReactIntlAdapter';
import FlagFormValidator from './FlagFormValidator';
import flagFormShape from '../../types/flagFormShape';
import LocationInput from '../../lib/inputs/LocationInput';
import TagsInput from '../../lib/inputs/TagsInput';
import Dropdown from '../../lib/Dropdown';
import TinyText from '../../lib/TinyText';
import TimeDropdowns from '../../lib/dropdowns/TimeDropdowns';
import FellowshipDropdown from '../../lib/dropdowns/FellowshipDropdown';
import DayOfWeekDropdown from '../../lib/dropdowns/DayOfWeekDropdown';
import LanguageDropdown from '../../lib/dropdowns/LanguageDropdown';
import UiStrategies from './UiStrategies';
import { valueKeys } from './FlagForm.configs.json';

const FlagFormDropdownItem = ({
  dropdownOptions,
  fieldName,
  flagForm,
  flagFormValidator,
  rowId,
  setFieldName,
  updateFlagForm,
}) => {
  const { formatMessage } = useIntl();

  const uiStrategies = new UiStrategies(flagFormValidator.mode);

  const handleFieldNameDropdownChange = ({ target: { value } }) => {
    setFieldName(rowId, value, fieldName);
  };
  const currentFieldNameDropdownOption = flagFormValidator.mode === 'admin'
    ? { id: fieldName, value: fieldName, label: formatMessage({ id: `flagForm-dropdownOption-supportTicket-${fieldName || 'unspecified'}` }) }
    : { id: fieldName, value: fieldName, label: formatMessage({ id: `flagForm-dropdownOption-${fieldName || 'unspecified'}` }) };

  /* this allows flagForm options like "changeRequest-submitPassword"
     to match up with meeting fields like "password" */
  const sanitizeFieldName = () => {
    if (fieldName.toLowerCase().includes('password')) { return 'password'; }
    if (fieldName.toLowerCase().includes('videolink')) { return 'videoLink'; }
    return fieldName;
  };
  const sanitizedFieldName = sanitizeFieldName(fieldName);

  const handleInput = ({ target: { name, value } }) => updateFlagForm({ [name]: value });
  const handleInputChange = ({ target: { value } }) => {
    updateFlagForm({ [sanitizedFieldName]: value });
  };

  const handleTagInput = ({ target: { value } }) => updateFlagForm({ tags: value });

  const basePlaceholderMessageKey = 'flagForm-inputPlaceholder';
  const sanitizedFieldNameKey = flagFormValidator.isValidFormField(fieldName)
    ? fieldName
    : 'unspecified';

  const inputPlaceholder = formatMessage({ id: `${basePlaceholderMessageKey}-${sanitizedFieldNameKey}` });

  const inputValidationClass = flagFormValidator.validate(fieldName)
    ? 'is-valid'
    : 'is-invalid';

  const calculatedInputClasses = flagFormValidator.shouldReflectInputValidationClass(fieldName)
    ? inputValidationClass
    : '';

  const inputValue = flagFormValidator.mode === 'default'
    ? flagForm[valueKeys[fieldName]]
    : flagForm[fieldName];

  const handleLocationInput = ({ geo, geoPreview }) => {
    updateFlagForm({
      geo,
      geoPreview,
    });
  };

  return (
    <Fragment>
      <div className="col-lg-4 col-md-9 ml-1">
        <label htmlFor="fieldName">
          <TinyText className="ml-1">Field Name</TinyText>
        </label>
        <Dropdown
          buttonClass="btn-outline-secondary ml-sm-1 mr-sm-4"
          defaultValue={currentFieldNameDropdownOption}
          label={formatMessage({ id: 'flagForm-dropdownOption-unspecified' })}
          name="fieldName"
          onChange={handleFieldNameDropdownChange}
          options={dropdownOptions}
          placeholder={formatMessage({ id: 'flagForm-dropdownOption-unspecified' })}
        />
      </div>
      <div className="col-lg-7" style={{ zIndex: '100000' }}>
        <div className="pl-lg-3 pl-md-0 pl-1">
          <Switch condition={uiStrategies.getStrategy(fieldName)}>
            <Case value="timekeeper">
              <label htmlFor="tags">
                <TinyText className="ml-1">
                  Value
                </TinyText>
              </label>
              <TimeDropdowns
                currentValue={flagForm[fieldName]}
                name={fieldName}
                onChange={handleInput}
              />
            </Case>
            <Case value="input">
              <label htmlFor="tags">
                <TinyText className="ml-1">
                  Value
                </TinyText>
              </label>
              <input
                className={`form-control ml-1 fadeInDown ${calculatedInputClasses}`}
                disabled={flagFormValidator.isInputFieldDisabled(fieldName)}
                onChange={handleInputChange}
                placeholder={inputPlaceholder}
                type="text"
                value={inputValue}
              />
            </Case>
            <Case value="geo">
              <label htmlFor="tags">
                <TinyText className="ml-1">
                  Value
                </TinyText>
              </label>
              <LocationInput
                currentValue={flagForm.geo}
                handleInput={handleLocationInput}
              />
            </Case>
            <Case value="language">
              <label htmlFor="tags">
                <TinyText className="ml-1">
                  Value
                </TinyText>
              </label>
              <LanguageDropdown onChange={handleInput} />
            </Case>
            <Case value="dayOfWeek">
              <label htmlFor="tags">
                <TinyText className="ml-1">
                  Value
                </TinyText>
              </label>
              <DayOfWeekDropdown onChange={handleInput} />
            </Case>
            <Case value="tags">
              <label htmlFor="tags">
                <TinyText className="ml-1">
                  Value
                </TinyText>
              </label>
              <TagsInput
                fellowship={flagForm.fellowship}
                onChange={handleTagInput}
                selectedTags={flagForm[fieldName]}
              />
            </Case>
            <Case value="boolean">
              <label htmlFor={fieldName}>
                <TinyText className="ml-1">
                  Value
                </TinyText>
              </label>
              <input
                className="form-control ml-md-1 mr-sm-4"
                name={fieldName}
                placeholder={formatMessage({ id: `flagForm-inputPlaceholder-${fieldName}` })}
                readOnly={true}
              />
            </Case>
            <Case value="fellowship">
              <FellowshipDropdown onChange={handleInput} />
            </Case>
            <Default>
              <label htmlFor="unspecifiedField">
                <TinyText className="ml-1">
                  Unspecified Field
                </TinyText>
              </label>
              <input
                className="form-control ml-md-1 mr-sm-4"
                name="unspecifiedField"
                placeholder={formatMessage({ id: `flagForm-inputPlaceholder-${flagFormValidator.mode}-unspecified` })}
                readOnly={true}
              />
            </Default>
          </Switch>
        </div>
      </div>
    </Fragment>
  );
};

FlagFormDropdownItem.displayName = 'FlagFormDropdownItem';

FlagFormDropdownItem.propTypes = {
  dropdownOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  fieldName: PropTypes.string.isRequired,
  flagFormValidator: PropTypes.instanceOf(FlagFormValidator).isRequired,
  flagForm: flagFormShape.isRequired,
  rowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  setFieldName: PropTypes.func.isRequired,
  updateFlagForm: PropTypes.func.isRequired,
};

export default FlagFormDropdownItem;
