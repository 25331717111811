export const SET_ADMIN_FILTERS = 'SET_ADMIN_FILTERS';
export const SET_TICKET_COUNT = 'SET_TICKET_COUNT';

export const setAdminFilters = (newFilters) => ({
  type: SET_ADMIN_FILTERS,
  payload: newFilters,
});

export const setTicketCount = (newCount) => ({
  type: SET_TICKET_COUNT,
  payload: newCount,
});

const initialState = {
  ticketCount: null,
  filters: {
    dayOfWeek: new Date().getDay(),
    ticketType: 'newMeetings',
    visibility: 'visible',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case SET_TICKET_COUNT:
      return {
        ...state,
        ticketCount: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
