import { capitalize } from 'lodash';
import languageNames from '../../configs/languageNames.json';

export default class TagLabelBuilder {
  constructor(formatMessage) {
    this.formatMessage = formatMessage;
  }

  assembleBasicTag(fellowship, tagType) {
    const baseTag = this.formatMessage({ id: `meetingTag-${fellowship}-${tagType}` });
    const assembledTag = tagType.includes('literature')
      ? `Literature (${baseTag})`
      : baseTag;

    return assembledTag;
  }

  assembleForeignLanguageTag(language) {
    const foreignLanguageIndicator = this.formatMessage({ id: 'meetingTag-foreignLanguage' });
    const languageNameInUsersLanguage = this.formatMessage({ id: `languageName-${language}` });
    const actualLanguageName = languageNames[language];

    return `${foreignLanguageIndicator} - ${languageNameInUsersLanguage} (${capitalize(actualLanguageName)})`;
  }

  build(fellowship, tagType, language) {
    const assembledTag = tagType === 'foreignLanguage'
      ? this.assembleForeignLanguageTag(language)
      : this.assembleBasicTag(fellowship, tagType);

    return assembledTag;
  }
}
