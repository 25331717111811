import { useState } from 'react';

const useToggle = (defaultState = false) => {
  const [toggleState, setToggleState] = useState(defaultState);

  const reverseToggle = () => setToggleState(!toggleState);

  return [toggleState, reverseToggle];
};

export default useToggle;
