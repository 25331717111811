import { isEmail, isURL } from 'validator';

export default class InputValidator {
  constructor() {
    this.mode = 'default';
  }

  validate({ name, value, isRequired }) {
    if (isRequired && this.isNully(value)) {
      return false;
    }

    switch (name) {
      case 'contactEmail':
        return this.validateEmail(value);
      case 'videoLink':
        return this.validateURL(value);
      default:
        return true;
    }
  }

  isNully(value) {
    return ['', null, undefined, NaN].includes(value);
  }

  validateEmail(value) {
    return !this.isNully(value) && isEmail(value);
  }

  validateURL(value) {
    return !this.isNully(value) && isURL(value);
  }
}
