import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { createResource, getResource } from 'iguazu-rest';
import { useAuth } from '../../lib/ReactAuthStub';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from '../../lib/ReactIntlAdapter';
import Button from '../../lib/Button';
import { closeNotesModal } from '../../redux/reducers/notes';
import { strategies } from './NotesModal.configs.json';

const NotesModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { user } = useAuth();

  const {
    apiCallback,
    isModalOpen,
    meetingId,
    meetingName,
    actionType,
    strategy,
  } = useSelector((state) => state.notes);

  const globalState = useSelector((state) => state);
  const userId = getResource({
    resource: 'users',
    id: get(user, 'sub', -1),
  })(globalState);

  const [userGeneratedNoteContent, setUserGeneratedNoteContent] = useState('');

  const closeModal = () => dispatch(closeNotesModal());
  const proceedHandler = () => {
    dispatch(createResource({
      resource: 'notes',
      id: meetingId,
      opts: {
        body: JSON.stringify({
          actionType,
          note: userGeneratedNoteContent,
          createdById: userId,
        }),
      },
    })).then(() => {
      if (apiCallback) {
        apiCallback();
      }
      closeModal();
    });
  };

  const actionPhrase = formatMessage({ id: `notesModal-actionPhrase-${actionType}` });

  const headerMessageContent = strategy === strategies.CONFIRMATION
    ? { actionPhrase, meetingName }
    : { meetingName };

  return (
    <Modal isOpen={isModalOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <FormattedMessage
          id={`notesModal-header-${strategy}`}
          values={headerMessageContent}
        />
      </ModalHeader>
      <ModalBody>
        {strategy === strategies.CONFIRMATION && (
          <div className="row">
            <div className="col-sm-12">
              <p>
                <FormattedMessage id={`notesModal-prompt-${strategy}`} />
              </p>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                className="form-control"
                rows={2}
                type="textarea"
                value={userGeneratedNoteContent}
                onChange={(({ target: { value } }) => setUserGeneratedNoteContent(value))}
              />
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12">
            <Button className="btn-primary" onClick={proceedHandler}>
              <FormattedMessage id="notesModal-buttonLabel-proceed" />
            </Button>
            <Button
              className="btn-outline-secondary ml-4"
              onClick={closeModal}
            >
              <FormattedMessage id="shareModal-button-close" />
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

NotesModal.displayName = 'NotesModal';

export default NotesModal;
