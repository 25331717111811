import React from 'react';
import PropTypes from 'prop-types';

const FilterHeader = ({ children }) => (
  <div className="row my-2 mb-2 d-flex align-items-center">
    <div className="col-12">
      <h6>{children}</h6>
    </div>
  </div>
);

FilterHeader.displayName = 'FilterHeader';

FilterHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FilterHeader;
