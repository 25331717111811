/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'regenerator-runtime';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import MeetingList from '../components/meeting-list/MeetingList';
import {
  clearPermanentFilters,
  setFilters,
  clearFilters,
} from '../redux/reducers/filters';

const IndexPage = ({ location }) => {
  console.log('indexPage');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearPermanentFilters());
  }, [dispatch]);

  useEffect(() => {
  // This function needs to be called after the component has rendered (in useEffect)
  // Otherwise, there are some undefined values in the form filters that break
    const populateFiltersFromSearch = () => {
      if (typeof location.search === 'string' && location.search.length > 1) {
        const initialFilters = {};
        const searchParams = new URLSearchParams(location.search.slice(1));
        for (const [key, value] of searchParams.entries()) {
          if (key === 'fellowships') {
            initialFilters[key] = value.split(',');
          } else { initialFilters[key] = value; }
        }

        dispatch(clearFilters());
        dispatch(setFilters(initialFilters));
      }
    };
    populateFiltersFromSearch();
  }, [dispatch, location.search]);

  return (
    <Layout>
      <SEO title="Home" />
      <MeetingList />
    </Layout>
  );
};
IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};
export default IndexPage;
