/* eslint-disable class-methods-use-this */
/* eslint-disable no-return-assign */
import { trim } from 'validator';

export default class GeoSorter {
  constructor(geo) {
    this.geo = geo;
  }

  sanitizeLocationArray(array) {
    const uniqueEntries = [...new Set(array)].filter((entry) => !!trim(entry || ' '));

    return uniqueEntries.sort();
  }

  filterUniqueEntries(key) {
    const allKeyOptions = this.geo.map((geoItem) => geoItem[key]);

    return this.sanitizeLocationArray(allKeyOptions);
  }

  getAllChildTypesForParentType(parentType, parentValue, childType) {
    const parentEntries = this.geo.filter((geoItem) => geoItem[parentType] === parentValue);
    const allChildEntries = parentEntries.map((parentEntry) => parentEntry[childType]);
    return this.sanitizeLocationArray(allChildEntries);
  }

  getAllAdministrativeAreas1(country) {
    const allAdministrativeArea1EntriesForCountry = this.getAllChildTypesForParentType('country', country, 'administrativeArea1');

    const administrativeAreas1WithChildTypes = allAdministrativeArea1EntriesForCountry
      .map((administrativeArea1) => ({
        allLocalities: this.getAllChildTypesForParentType('administrativeArea1', administrativeArea1, 'locality'),
        allAdministrativeAreas2: this.getAllAdministrativeAreas2(administrativeArea1),
      }));

    const administrativeAreas1Object = {};
    allAdministrativeArea1EntriesForCountry
      .forEach((administrativeArea1, index) => {
        administrativeAreas1Object[administrativeArea1] = administrativeAreas1WithChildTypes[index];
      });

    return administrativeAreas1Object;
  }

  getAllAdministrativeAreas2(administrativeArea1) {
    const allAdministrativeArea2EntriesForAdministrativeArea1 = this.getAllChildTypesForParentType('administrativeArea1', administrativeArea1, 'administrativeArea2');

    const administrativeAreas2WithChildTypes = allAdministrativeArea2EntriesForAdministrativeArea1
      .map((administrativeArea2) => ({
        allLocalities: this.getAllChildTypesForParentType('administrativeArea2', administrativeArea2, 'locality'),
      }));

    const administrativeAreas2Object = {};
    allAdministrativeArea2EntriesForAdministrativeArea1.forEach((administrativeArea2, index) => {
      administrativeAreas2Object[administrativeArea2] = administrativeAreas2WithChildTypes[index];
    });

    return administrativeAreas2Object;
  }

  nestLocationEntries() {
    const allCountries = this.filterUniqueEntries('country');

    const countryShapes = allCountries.map((country) => ({
      allAdministrativeAreas1: this.getAllAdministrativeAreas1(country),
      allLocalities: this.getAllChildTypesForParentType('country', country, 'locality'),
    }));

    const countryObject = {};

    allCountries.forEach((countryName, index) => countryObject[countryName] = countryShapes[index]);

    return countryObject;
  }
}
