export const LOAD_FELLOWSHIPS = 'LOAD_FELLOWSHIPS';
export const LOAD_FELLOWSHIPS_SUCCESS = 'LOAD_FELLOWSHIPS_SUCCESS';
export const LOAD_FELLOWSHIPS_ERROR = 'LOAD_FELLOWSHIPS_ERROR';

export const loadFellowshipsSuccess = (payload) => ({
  type: LOAD_FELLOWSHIPS_SUCCESS,
  payload,
});

export const loadFellowshipsError = (error) => ({
  type: LOAD_FELLOWSHIPS_ERROR,
  error,
});

export const loadFellowships = () => (dispatch) => {
  dispatch({ type: LOAD_FELLOWSHIPS });

  fetch('https://steps-video-93623.appspot.com/api/v1/fellowships')
    .then((res) => res.json())
    .then((data) => dispatch(loadFellowshipsSuccess(data)))
    .catch((err) => dispatch(loadFellowshipsError(err)));
};

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FELLOWSHIPS:
      return { ...state, isLoading: true };
    case LOAD_FELLOWSHIPS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };
    case LOAD_FELLOWSHIPS_ERROR:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export default reducer;
