import { isEqual } from 'lodash';
import FlagFormValidator from '../flag-form/FlagFormValidator';
import { flagFormOptions, uiStrategies } from './SupportTicket.configs.json';

export default class SupportTicketValidator extends FlagFormValidator {
  constructor(flagForm, flagFormDefaultState) {
    super();
    this.flagForm = flagForm;
    this.flagFormDefaultState = flagFormDefaultState;
    this.dropdownOptions = flagFormOptions;
    this.customInputFieldTypes = uiStrategies.input;
    this.mode = 'admin';
  }

  shouldReflectInputValidationClass() {
    return false;
  }

  isBooleanFieldType() {
    return false;
  }

  getUpdatedFields() {
    const fieldsWithContent = this.getFieldNamesWithContent();

    const changedFields = fieldsWithContent.filter((fieldName) => fieldName !== 'fellowships'
      && !isEqual(this.flagForm[fieldName], this.flagFormDefaultState[fieldName]));

    return changedFields;
  }
}
