import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { get } from 'immutable';
import { FormattedMessage } from '../../lib/ReactIntlAdapter';
import Button from '../../lib/Button';
import CopyButton from '../../lib/CopyButton';
import { closeShareModal } from '../../redux/reducers/sharing';

const ShareModal = () => {
  const dispatch = useDispatch();
  const { content, isModalOpen, strategy } = useSelector((state) => state.sharing);

  const headerMessageContent = strategy === 'individualMeeting'
    ? { meetingName: get(content, 'meetingName', '') }
    : null;

  const closeModal = () => dispatch(closeShareModal());

  return (
    <Modal isOpen={isModalOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        {strategy && (
          <FormattedMessage
            id={`shareModal-header-${strategy}`}
            values={headerMessageContent}
          />
        )}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-sm-12">
            <p>
              <FormattedMessage id="shareModal-body-prompt" />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              {strategy === 'searchQuery' ? (
                <textarea
                  className="form-control"
                  disabled={true}
                  rows={4}
                  type="textarea"
                  value={content.link}
                />
              ) : (
                <input
                  className="form-control"
                  disabled={true}
                  rows={4}
                  type="textarea"
                  value={content.link}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12">
            <CopyButton
              className="btn-success"
              copyText={content.link}
            />
            <Button
              className="btn-outline-secondary ml-4"
              onClick={closeModal}
            >
              <FormattedMessage id="shareModal-button-close" />
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ShareModal.displayName = 'ShareModal';

export default ShareModal;
