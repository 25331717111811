import React from 'react';
import PropTypes from 'prop-types';

const CircleFill = ({ size }) => (
  <svg className="bi bi-chevron-right" width={size} height={size} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" />
  </svg>
);

CircleFill.displayName = 'CircleFill';

CircleFill.propTypes = {
  size: PropTypes.string.isRequired,
};

export default CircleFill;
