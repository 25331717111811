/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import { configureIguazuREST, resourcesReducer } from 'iguazu-rest';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import regeneratorRuntime from 'regenerator-runtime';
import 'isomorphic-fetch';
import { BASE_URL } from './apiRequest';
import resources from './resources';
import reducers from './reducers';

configureIguazuREST({
  resources,
  defaultOpts: {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': BASE_URL,
      'Content-Type': 'application/json',
    },
  },
});

const createStore = () => reduxCreateStore(combineReducers({
  resources: resourcesReducer,
  ...reducers,
}), composeWithDevTools(
  applyMiddleware(thunk)
));

export default createStore;
