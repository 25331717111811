import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { useCookies } from 'react-cookie';
import { useMeta } from '../../hooks/useMeta';
import { TEN_YEARS_FROM_NOW } from '../../lib/constants';
import { activateShareModal } from '../../redux/reducers/sharing';
import Button from '../../lib/Button';
import TinyText from '../../lib/TinyText';
import ShareIcon from '../../lib/icons/ShareIcon';
import Star from '../../lib/icons/Star';
import StarFill from '../../lib/icons/StarFill';
import Eye from '../../lib/icons/Eye';
import EyeSlash from '../../lib/icons/EyeSlash';
import Pencil from '../../lib/icons/Pencil';

import { setFavorites } from '../../redux/reducers/filters';

const MeetingItemActionButtons = ({
  fellowship,
  flagFormStatus,
  isHidden,
  meetingId,
  meetingName,
  onPencilClick,
  toggleMeetingHiddenState,
}) => {
  const { mode } = useMeta();
  const { pathname = '' } = useLocation();

  const dispatch = useDispatch();

  const shareModalClickHandler = () => {
    dispatch(activateShareModal({
      content: {
        link: `https://www.12steps.video/meetings/${fellowship.toLowerCase()}/${meetingId}`,
        meetingName,
      },
      strategy: 'individualMeeting',
    }));
  };

  const { favorites } = useSelector((state) => state.filters);

  // eslint-disable-next-line no-unused-vars
  const [_, setCookie] = useCookies('favorites');
  useEffect(() => { setCookie('favorites', { favorites }, { path: '/', maxAge: TEN_YEARS_FROM_NOW }); }, [favorites, setCookie]);

  const handleFavoriteClick = () => {
    if (!favorites.includes(meetingId)) {
      const favoritesWithMe = [...favorites, meetingId];
      dispatch(setFavorites(favoritesWithMe));
    } else {
      const favoritesWithoutMe = favorites
        .filter((favoritedMeetingId) => favoritedMeetingId !== meetingId);
      dispatch(setFavorites(favoritesWithoutMe));
    }
  };

  const isActiveEditForm = flagFormStatus.isActive
    && flagFormStatus.type === 'edit';
  const shouldShowAdminButtons = mode === 'admin'
    || isActiveEditForm;

  return !pathname.includes('/new/') && (
    <div className="animated fadeIn">
      {shouldShowAdminButtons ? (
        <Fragment>
          <Button
            className="btn-tertiary btn-sm"
            onClick={onPencilClick}
          >
            <Fragment>
              <span className="text-success">
                <Pencil size="1.25em" />
              </span>
              <TinyText className="text-dark">Edit</TinyText>
            </Fragment>
          </Button>
          <Button
            className="btn-tertiary btn-sm text-warning p-0 mr-2"
            onClick={toggleMeetingHiddenState}
          >
            {isHidden ? (
              <Fragment>
                <span className="text-danger">
                  <EyeSlash size="1.5em" />
                </span>
                <TinyText className="text-dark">Unhide</TinyText>
              </Fragment>
            ) : (
              <span className="text-dark">
                <Eye size="1.5em" />
                <TinyText className="text-dark">Hide</TinyText>
              </span>
            )}
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            className="btn-tertiary btn-sm text-warning p-0 mr-2"
            onClick={handleFavoriteClick}
          >
            {favorites.includes(meetingId) ? (
              <Fragment>
                <StarFill size="1.5em" />
                <TinyText className="text-dark">Saved</TinyText>
              </Fragment>
            ) : (
              <span className="text-dark">
                <Star size="1.5em" />
                <TinyText className="text-dark">Save</TinyText>
              </span>
            )}
          </Button>
          <Button
            className="btn-tertiary btn-sm"
            onClick={shareModalClickHandler}
          >
            <Fragment>
              <ShareIcon size="1.25em" />
              <TinyText className="text-dark">Share</TinyText>
            </Fragment>
          </Button>
        </Fragment>
      )}
    </div>
  );
};

MeetingItemActionButtons.displayName = 'MeetingItemActionButtons';

MeetingItemActionButtons.propTypes = {
  fellowship: PropTypes.string.isRequired,
  flagFormStatus: PropTypes.shape({
    isActive: PropTypes.bool,
    type: PropTypes.oneOf(['edit', 'flagForm']),
  }).isRequired,
  isHidden: PropTypes.bool.isRequired,
  meetingId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  meetingName: PropTypes.string.isRequired,
  onPencilClick: PropTypes.func.isRequired,
  toggleMeetingHiddenState: PropTypes.func.isRequired,
};

export default MeetingItemActionButtons;
