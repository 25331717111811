export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CLEAR_PERMANENT_FILTERS = 'CLEAR_PERMANENT_FILTERS';
export const CLEAR_MEETING_NAME_FILTER = 'CLEAR_MEETING_NAME_FILTER';

export const RESET_FILTERS = 'RESET_FILTERS';

export const SET_FAVORITES = 'SET_FAVORITES';

export const SET_FILTERS = 'SET_FILTERS';
export const SET_PERMANENT_FILTERS = 'SET_PERMANENT_FILTERS';
export const SET_PREFERENCES = 'SET_PREFERENCES';

export const TOGGLE_FILTER_MODAL = 'TOGGLE_FILTER_MODAL';

export const clearFilter = (filterToClear) => ({
  type: CLEAR_FILTER,
  payload: filterToClear,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});

export const clearPermanentFilters = () => ({
  type: CLEAR_PERMANENT_FILTERS,
});

export const clearMeetingNameFilter = () => ({
  type: CLEAR_MEETING_NAME_FILTER,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const setFavorites = (newFavorites) => ({
  type: SET_FAVORITES,
  payload: newFavorites,
});

export const setFilters = (newFilters) => ({
  type: SET_FILTERS,
  payload: newFilters,
});

export const setPermanentFilters = (newFilters) => ({
  type: SET_PERMANENT_FILTERS,
  payload: newFilters,
});

export const setPreferences = (newPreferences) => ({
  type: SET_PREFERENCES,
  payload: newPreferences,
});

export const toggleFilterModal = () => ({
  type: TOGGLE_FILTER_MODAL,
});

const clearedFilters = {
  administrativeArea1: null,
  administrativeArea2: null,
  country: null,
  dayOfWeek: null,
  fellowship: 'all',
  locality: null,
  tags: null,
  timeOfDay: '',
};

const initialState = {
  activeFilters: {
    administrativeArea1: '',
    administrativeArea2: '',
    country: '',
    dayOfWeek: new Date().getDay(),
    fellowship: 'all',
    locality: '',
    meetingName: '',
    tags: '',
    timeOfDay: 'upcoming',
  },
  adminFilters: {
    dayOfWeek: new Date().getDay(),
    ticketType: 'flaggedMeetings',
    visibility: 'visible',
  },
  favorites: [],
  isFilterModalOpen: false,
  permanentFilters: {},
  preferences: {},
  timeZone: new Date().getTimezoneOffset(),
};

export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_FILTER:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          [action.payload]: clearedFilters[action.payload],
        },
      };
    case CLEAR_FILTERS:
      return { ...state, activeFilters: clearedFilters };
    case CLEAR_MEETING_NAME_FILTER:
      return { ...state, activeFilters: { ...state.activeFilters, meetingName: null } };
    case RESET_FILTERS:
      return { ...state, activeFilters: initialState.activeFilters };
    case SET_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          ...action.payload,
        },
      };
    case SET_PERMANENT_FILTERS:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          ...action.payload,
        },
        permanentFilters: {
          ...state.permanentFilters,
          ...action.payload,
        },
      };
    case SET_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload,
        },
      };
    case TOGGLE_FILTER_MODAL:
      return {
        ...state,
        isFilterModalOpen: !state.isFilterModalOpen,
      };
    default:
      return state;
  }
}
