import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '../../lib/ReactIntlAdapter';
import TagLabelBuilder from './TagLabelBuilder';

const ExclusiveTag = ({
  fellowship,
  tagType,
}) => {
  const { formatMessage } = useIntl();

  const labelBuilder = new TagLabelBuilder(formatMessage);
  const tagLabel = labelBuilder.build(fellowship, tagType);

  return (
    <div>
      <small className="text-danger">
        {tagLabel}
      </small>
    </div>
  );
};

ExclusiveTag.displayName = 'ExclusiveTag';

ExclusiveTag.propTypes = {
  fellowship: PropTypes.string.isRequired,
  tagType: PropTypes.string.isRequired,
};

export default ExclusiveTag;
