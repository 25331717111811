import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '../ReactIntlAdapter';
import Dropdown from '../Dropdown';

const TimeOfDayDropdown = ({ className, onChange }) => {
  const { formatMessage } = useIntl();

  const allTimeOfDayOption = {
    id: '',
    value: '',
    label: formatMessage({ id: 'timeOfDay-all' }),
  };

  const timesOfDay = [
    'upcoming',
    'morning',
    'midday',
    'evening',
    'night',
  ];

  const timeOfDayOptions = timesOfDay.map((name) => {
    const hoursIndicator = name === 'upcoming'
      ? ''
      : ` ${formatMessage({ id: `timeOfDay-${name}-hours` })}`;

    return {
      id: name,
      label: `${formatMessage({ id: `timeOfDay-${name}` })}${hoursIndicator}`,
      value: name,
    };
  });

  return (
    <Dropdown
      buttonClass={`btn-outline-secondary ${className}`}
      label={formatMessage({ id: 'dropdownLabel-timeOfDay' })}
      name="timeOfDay"
      onChange={onChange}
      options={[...timeOfDayOptions, allTimeOfDayOption]}
    />
  );
};

TimeOfDayDropdown.displayName = 'TimeOfDayDropdown';

TimeOfDayDropdown.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TimeOfDayDropdown.defaultProps = {
  className: '',
};

export default TimeOfDayDropdown;
