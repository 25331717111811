import React from 'react';
import PropTypes from 'prop-types';
import MeetingByIdTemplate from './MeetingByIdTemplate';

const MeetingByIdWrapper = ({
  id,
}) => (
  <MeetingByIdTemplate id={id} location={{ pathname: 'stubbed' }} />
);

MeetingByIdWrapper.displayName = 'MeetingByIdWrapper';

MeetingByIdWrapper.propTypes = {
  id: PropTypes.number.isRequired,
};

export default MeetingByIdWrapper;
