import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../ReactIntlAdapter';
import { convertToMilitaryTime, formatHour } from '../../utils/formatHour';
import Dropdown from '../Dropdown';
import TinyText from '../TinyText';

const TimeDropdowns = ({
  currentValue,
  name,
  onChange,
}) => {
  const formattedHour = formatHour(currentValue);
  const currentMeridian = formattedHour.slice(-2);
  const hourWithoutMeridian = formattedHour.slice(0, -2);
  const [currentHour, currentMinute] = hourWithoutMeridian.split(':');

  const twelveHourTimes = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  const fiveMinuteIntervals = [
    '00',
    '05',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
  ];

  const twelveHourOptions = twelveHourTimes.map((hour) => ({
    id: hour,
    value: hour,
    label: hour,
    selected: Number(hour) === Number(currentHour),
  }));

  const minuteOptions = fiveMinuteIntervals.map((interval) => ({
    id: interval,
    value: interval,
    label: interval,
  }));

  const handleInput = ({ target: { name: inputName, value } }) => {
    if (inputName === 'hour') {
      const newMilitaryTime = convertToMilitaryTime(value, currentMinute, currentMeridian);
      const newValue = Number(newMilitaryTime);
      return onChange({ target: { name, value: newValue } });
    }
    if (inputName === 'minute') {
      const newMilitaryTime = convertToMilitaryTime(currentHour, value, currentMeridian);
      const newValue = Number(newMilitaryTime);
      return onChange({ target: { name, value: newValue } });
    }
    if (inputName === `${name}-meridian`) {
      const newMilitaryTime = convertToMilitaryTime(currentHour, currentMinute, value);
      const newValue = Number(newMilitaryTime);
      return onChange({ target: { name, value: newValue } });
    }

    return null;
  };

  return (
    <div className="row">
      <div className="col-4 d-flex flex-column">
        <Dropdown
          buttonClass="btn-outline-secondary"
          onChange={handleInput}
          name="hour"
          options={twelveHourOptions}
        />
        <label htmlFor="hour">
          <TinyText>
            <FormattedMessage id="timeDropdown-label-hour" />
          </TinyText>
        </label>
      </div>
      <div className="col-4 d-flex flex-column">
        <Dropdown
          buttonClass="btn-outline-secondary"
          onChange={handleInput}
          name="minute"
          options={minuteOptions}
        />
        <label htmlFor="minute">
          <TinyText>
            <FormattedMessage id="timeDropdown-label-minute" />
          </TinyText>
        </label>
      </div>
      <div className="col-4 d-flex justify-content-start align-items-center">
        <div className="d-flex flex-column align-items-center mr-4">
          <input
            className="form-control"
            defaultChecked={currentMeridian === 'am'}
            onChange={handleInput}
            name={`${name}-meridian`}
            value="am"
            type="radio"
          />
          <label htmlFor={`${name}-meridian`}>
            <TinyText>
              <FormattedMessage id="timeDropdown-label-meridian-am" />
            </TinyText>
          </label>
        </div>
        <div className="d-flex flex-column align-items-center">
          <input
            className="form-control"
            defaultChecked={currentMeridian === 'pm'}
            onChange={handleInput}
            name={`${name}-meridian`}
            value="pm"
            type="radio"
          />
          <label htmlFor={`${name}-meridian`}>
            <TinyText>
              <FormattedMessage id="timeDropdown-label-meridian-pm" />
            </TinyText>
          </label>
        </div>
      </div>
    </div>
  );
};

TimeDropdowns.displayName = 'TimeDropdowns';

TimeDropdowns.propTypes = {
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TimeDropdowns;
