import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { createResource } from 'iguazu-rest';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from '../../lib/ReactIntlAdapter';
import Button from '../../lib/Button';
import flagFormShape from '../../types/flagFormShape';
import FlagFormValidator from './FlagFormValidator';

const FlagFormFooter = ({
  flagForm,
  meetingId,
  resetFlagForm,
  toggleFlagMode,
  triggerToast,
  updateFlagForm,
}) => {
  const { formatMessage } = useIntl();
  const updateRequestorEmail = ({ target: { value } }) => updateFlagForm({
    requestorEmail: value,
  });

  const dispatch = useDispatch();

  const flagFormValidator = new FlagFormValidator(flagForm);

  const submitFlagForm = async () => {
    const fieldNamesWithContent = flagFormValidator.getFieldNamesWithContent();
    const validFieldNames = fieldNamesWithContent.filter((fieldName) => fieldName !== 'notes');
    const flagFormRows = validFieldNames.map((fieldName) => ({
      supportKey: fieldName,
      value: flagForm[fieldName],
    }));

    await dispatch(createResource({
      resource: 'support',
      id: {
        meetingId,
        flagFormRows,
      },
      opts: {
        body: JSON.stringify({
          meetingId,
          flagFormRows,
          notes: get(flagForm, ['notes'], ''),
        }),
      },
    }))
      .then(() => {
        triggerToast({
          message: formatMessage({ id: 'flagForm-toast-submitted' }),
          sentiment: 'success',
        });
      })
      .catch(() => {
        triggerToast({
          message: formatMessage({ id: 'flagForm-toast-failed' }),
          sentiment: 'danger',
        });
      });
    toggleFlagMode();
    resetFlagForm();
  };

  const cancelFlagging = () => {
    toggleFlagMode();
    resetFlagForm();
    triggerToast({
      message: formatMessage({ id: 'flagForm-toast-cancelled' }),
      sentiment: 'secondary',
    });
  };

  const requestorEmailValidationClass = flagFormValidator.validate('requestorEmail')
    ? 'is-valid'
    : 'is-invalid';
  const calculatedRequestorEmailClasses = flagFormValidator.shouldReflectInputValidationClass('requestorEmail')
    ? requestorEmailValidationClass
    : '';

  const needsRequestorEmail = flagFormValidator.hasEmailRequiredFields()
    && !flagFormValidator.validate('requestorEmail');

  const isSubmitButtonDisabled = flagFormValidator.isEmpty()
    || flagFormValidator.hasInvalidFields()
    || needsRequestorEmail;

  const submitButtonBaseClass = isSubmitButtonDisabled
    ? 'btn-danger'
    : 'btn-primary';

  const hasRequestorEmailEvenWhenNotRequired = !isSubmitButtonDisabled
    && flagFormValidator.validate('requestorEmail');
  const submitButtonClass = hasRequestorEmailEvenWhenNotRequired
    ? 'btn-success'
    : submitButtonBaseClass;

  return (
    <div className="form-row align-items-center animated fadeInDown">
      <div className="col-lg-6 mb-2">
        <input
          className={`form-control ${calculatedRequestorEmailClasses} ml-md-1`}
          id={`meetingItem-${meetingId}-footer-flagMode-input-requestorEmail`}
          onChange={updateRequestorEmail}
          placeholder="Contact Email"
          type="text"
          value={flagForm.requestorEmail || ''}
        />
      </div>
      <div className="col-lg-6 col-sm-12">
        <Button
          className={`mr-2 ml-md-1 mb-lg-2 ${submitButtonClass}`}
          disabled={isSubmitButtonDisabled}
          onClick={submitFlagForm}
        >
          <FormattedMessage id="flagForm-cta-submit" />
        </Button>
        <Button
          className="btn-outline-secondary mb-lg-2"
          onClick={cancelFlagging}
        >
          <FormattedMessage id="flagForm-cta-cancel" />
        </Button>
      </div>
    </div>
  );
};

FlagFormFooter.displayName = 'FlagFormFooter';

FlagFormFooter.propTypes = {
  flagForm: flagFormShape.isRequired,
  meetingId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  resetFlagForm: PropTypes.func.isRequired,
  toggleFlagMode: PropTypes.func.isRequired,
  triggerToast: PropTypes.func.isRequired,
  updateFlagForm: PropTypes.func.isRequired,
};

export default FlagFormFooter;
