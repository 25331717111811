export const ACTIVATE_FEEDBACK_MODAL = 'ACTIVATE_FEEDBACK_MODAL';
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';

export const activateFeedbackModal = () => ({
  type: ACTIVATE_FEEDBACK_MODAL,
});

export const closeFeedbackModal = () => ({
  type: CLOSE_FEEDBACK_MODAL,
});

const initialState = {
  isModalOpen: false,
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_FEEDBACK_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case CLOSE_FEEDBACK_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default feedbackReducer;
