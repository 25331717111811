import { strategies } from '../../components/modals/NotesModal.configs.json';

export const ACTIVATE_NOTES_MODAL = 'ACTIVATE_NOTES_MODAL';
export const CLOSE_NOTES_MODAL = 'CLOSE_NOTES_MODAL';

export const activateNotesModal = (payload) => ({
  type: ACTIVATE_NOTES_MODAL,
  payload,
});

export const closeNotesModal = () => ({
  type: CLOSE_NOTES_MODAL,
});

const initialState = {
  apiCallback: null,
  isModalOpen: false,
  meetingId: '',
  meetingName: '',
  actionType: 'COMMENT',
  strategy: strategies.NEUTRAL,
};

const sharingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_NOTES_MODAL:
      return {
        ...state,
        apiCallback: action.payload.apiCallback,
        isModalOpen: true,
        meetingId: action.payload.meetingId,
        meetingName: action.payload.meetingName,
        actionType: action.payload.actionType,
        strategy: action.payload.strategy,
      };
    case CLOSE_NOTES_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default sharingReducer;
