import React from 'react';

const Spinner = () => (
  <div className="d-flex justify-content-center">
    <div className="spinner-border text-primary my-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

Spinner.displayName = 'Spinner';

export default Spinner;
