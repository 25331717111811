import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader = () => (
  <div className="card">
    <div className="card-body">
      <ContentLoader
        speed={2}
        width={450}
        height={160}
        viewBox="0 0 450 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="191" y="52" rx="3" ry="3" width="67" height="11" />
        <rect x="36" y="52" rx="3" ry="3" width="140" height="11" />
        <rect x="147" y="102" rx="3" ry="3" width="53" height="11" />
        <rect x="207" y="102" rx="3" ry="3" width="72" height="11" />
        <rect x="38" y="102" rx="3" ry="3" width="100" height="11" />
        <rect x="35" y="9" rx="3" ry="3" width="159" height="32" />
        <rect x="38" y="139" rx="3" ry="3" width="147" height="15" />
      </ContentLoader>
    </div>
  </div>
);

export default MyLoader;
