import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { pickBy } from 'lodash';
import { connectAsync } from 'iguazu';
import { queryCollection } from 'iguazu-rest';
import { useAuth } from '../../lib/ReactAuthStub';
import tagShape from '../../types/tagShape';
import MeetingItem from '../meeting-item/MeetingItem';
import NoMeetingsItem from './NoMeetingsItem';

const ListSegment = ({
  addLastId,
  isLoading,
  lastId,
  loadedWithErrors,
  meetings,
}) => {
  const { isAuthorized } = useAuth();
  const meetingsToShow = isAuthorized('SUPER_ADMIN')
    ? meetings
    : meetings.filter(({ isHidden }) => !isHidden);

  const isLoaded = !isLoading()
    && !loadedWithErrors();

  useEffect(() => {
    if (meetingsToShow.length > 0) {
      const [lastMeeting] = meetingsToShow.slice(-1);
      addLastId(lastMeeting.id);
    }
  }, [isLoaded, addLastId, meetingsToShow]);

  const shouldShowNoMeetingsItem = lastId === 0
    && meetingsToShow.length < 1;

  return isLoaded && (
    <Fragment>
      {meetingsToShow.length > 0 && meetingsToShow.map((meetingItem) => (
        <div
          className="row animated fadeIn"
          key={`meetingList-listSegment-lastId-${lastId}-meetingRow-${meetingItem.id}`}
        >
          <div className="col-md-12 m-0 mb-3">
            <MeetingItem meeting={meetingItem} />
          </div>
        </div>
      ))}
      {shouldShowNoMeetingsItem && (
        <NoMeetingsItem />
      )}
    </Fragment>
  );
};

ListSegment.displayName = 'ListSegment';

ListSegment.propTypes = {
  addLastId: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  lastId: PropTypes.number,
  loadedWithErrors: PropTypes.func.isRequired,
  meetings: PropTypes.arrayOf(PropTypes.shape({
    clickToCallLink: PropTypes.string,
    dayOfWeek: PropTypes.number,
    endTime: PropTypes.number,
    fellowship: PropTypes.string,
    onlineMeetingId: PropTypes.string,
    meetingName: PropTypes.string,
    startTime: PropTypes.number,
    tags: PropTypes.arrayOf(tagShape),
    videoLink: PropTypes.string,
  })),
};

ListSegment.defaultProps = {
  lastId: 0,
  meetings: [],
};

export const loadDataAsProps = ({
  store: { dispatch, getState },
  ownProps: { lastId },
}) => {
  const { filters: { activeFilters } } = getState();

  const validFilters = pickBy(activeFilters, (item) => !!item || item === 0);

  const query = {
    lastId,
    pageSize: 25,
    ...validFilters,
  };

  return {
    meetings: () => dispatch(queryCollection({
      resource: 'meetings',
      id: {
        ...query,
      },
      opts: { query },
    })),
  };
};

export default connectAsync({ loadDataAsProps })(ListSegment);
