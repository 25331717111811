import React from 'react';
import 'bootstrap';
import 'jquery';
import 'popper.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Router } from '@reach/router';
import './i18n';
import Providers from './redux/wrapWithProvider';
import './lib/styles/animate.css';
import './lib/styles/global.css';
import 'isomorphic-fetch';
import 'react-toggle/style.css';
import IndexPage from './pages';
import Auth0Callback from './pages/auth0_callback';
import MeetingByIdWrapper from './templates/MeetingByIdWrapper';

const TestRoute = () => <h1>Test Route</h1>;

const App = () => (
  <Providers>
    <Router>
      <IndexPage path="/" />
      <TestRoute path="/test" />
      <MeetingByIdWrapper path="/meetings/:fellowship/:id" />
      <Auth0Callback path="/auth0_callback" />
    </Router>
  </Providers>
);

export default App;
