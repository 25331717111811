import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from '../../lib/ReactIntlAdapter';
import MetaMessageBuilder from './MetaMessageBuilder';

const ResultsMeta = ({ count }) => {
  const { activeFilters: filters } = useSelector((state) => state.filters);
  const { formatMessage } = useIntl();
  const metaMessageBuilder = new MetaMessageBuilder({ count, filters, formatMessage });
  const metaMessage = metaMessageBuilder.execute();

  return count > 0 && (
    <div className="row anim fade-in">
      <div className="col-md-12 ml-1">
        <small>
          <p>
            <em>{metaMessage}</em>
          </p>
        </small>
      </div>
    </div>
  );
};

ResultsMeta.displayName = 'ResultsMeta';

ResultsMeta.propTypes = {
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

ResultsMeta.defaultProps = {
  count: '',
};

export default ResultsMeta;
