import Styled from '@emotion/styled';

const TinyText = Styled.p`
  font-size: .5em;
  text-transform: uppercase;
  margin-top: .5em;
  margin-bottom: .5em;
`;

export default TinyText;
