import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pickBy } from 'lodash';
import Toggle from 'react-toggle';
import { FormattedMessage, useIntl } from '../../lib/ReactIntlAdapter';
import { clearFilter } from '../../redux/reducers/filters';

const NoMeetingsItem = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { activeFilters } = useSelector((state) => state.filters);
  const validFilters = pickBy(activeFilters, (item) => {
    const isEmptyArray = Array.isArray(item)
      && item.length === 0;

    if (isEmptyArray) { return false; }

    return !!item || item === 0;
  });

  const getFilterLabel = (filterName) => {
    const baseFilterLabel = formatMessage({ id: `dropdownLabel-${filterName}` });
    const rawFilterContent = validFilters[filterName];

    if (filterName === 'fellowship') {
      const formattedFellowship = formatMessage({
        id: `fellowshipShortName-${validFilters.fellowship}`,
      });

      return `${baseFilterLabel} - ${formattedFellowship}`;
    }

    if (filterName === 'dayOfWeek') {
      const selectedDayOfWeek = validFilters[filterName];
      const formattedDayOfWeek = formatMessage({ id: `day-${selectedDayOfWeek}-name` });

      return `${baseFilterLabel} - ${formattedDayOfWeek}`;
    }

    if (filterName === 'timeOfDay') {
      const selectedTimeOfDay = validFilters[filterName];
      const formattedTimeOfDay = formatMessage({ id: `timeOfDay-${selectedTimeOfDay}` });

      return `${baseFilterLabel} - ${formattedTimeOfDay}`;
    }

    return `${baseFilterLabel} - ${rawFilterContent}`;
  };

  const activeFilterOptions = Object.keys(validFilters).map((filterName) => ({
    value: filterName,
    label: getFilterLabel(filterName),
  }));

  return (
    <div className="row animated fadeIn">
      <div className="col-md-12 m-sm-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <h5 className="card-title">
                  <FormattedMessage id="error-noMeetings" />
                </h5>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12">
                <p className="card-subtitle mb-2 text-muted">
                  <FormattedMessage id="error-noMeetings-cta" />
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <table className="table">
                  <tbody>
                    {activeFilterOptions.map(({ label, value }) => (
                      <tr key={`noMeetingsItem-activeFilterOption-${value}`}>
                        <td>{label}</td>
                        <td>
                          <Toggle
                            checked={true}
                            onChange={() => dispatch(clearFilter(value))}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NoMeetingsItem.displayName = 'NoMeetingsItem';

export default NoMeetingsItem;
