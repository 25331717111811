/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '../../lib/ReactIntlAdapter';
import { useMeta } from '../../hooks/useMeta';
import flagFormShape from '../../types/flagFormShape';
import FlagFormValidator from './FlagFormValidator';
import FlagFormRowButtons from './FlagFormRowButtons';
import FlagFormDropdownItem from './FlagFormDropdownItem';

const FlagFormRow = ({
  addRow,
  deleteRow,
  fieldName,
  formLength,
  formRows,
  flagForm,
  flagFormValidator,
  isEditMode,
  rowId,
  rowIndex,
  setFieldName,
  updateFlagForm,
}) => {
  const { mode } = useMeta();
  const { formatMessage } = useIntl();

  const baseMessageKey = flagFormValidator.mode === 'admin'
    ? 'flagForm-dropdownOption-supportTicket'
    : 'flagForm-dropdownOption';

  const myFieldNameMessageKey = flagFormValidator.isValidFormField(fieldName)
    ? `${baseMessageKey}-${fieldName}`
    : `${baseMessageKey}-unspecified`;

  const myDropdownOption = {
    id: fieldName,
    label: formatMessage({ id: `${myFieldNameMessageKey}` }),
    selected: true,
  };

  const sanitizedDropdownOptions = flagFormValidator.mode === 'admin' && mode !== 'admin'
    ? flagFormValidator.dropdownOptions.filter((optionName) => !['fellowship', 'geo'].includes(optionName))
    : flagFormValidator.dropdownOptions;

  const availableDropdownOptions = sanitizedDropdownOptions
    .filter((dropdownOption) => flagFormValidator
      .isDropdownOptionAvailable(dropdownOption, formRows));

  const dropdownOptionsWithLabels = availableDropdownOptions.map((optionName) => ({
    id: optionName,
    label: formatMessage({ id: `${baseMessageKey}-${optionName}` }),
    value: optionName,
  }));

  const unselectedOption = {
    id: '',
    label: formatMessage({ id: 'flagForm-dropdownOption-unspecified' }),
    value: '',
    disabled: true,
  };

  const dropdownOptionsToShow = flagFormValidator.isValidFormField(fieldName)
    ? [myDropdownOption, ...dropdownOptionsWithLabels]
    : [unselectedOption, ...dropdownOptionsWithLabels];

  return (
    <div className="form-row align-items-center animated fadeInDown">
      <div className="col-12">
        <div className="row d-flex align-items-start">
          <FlagFormDropdownItem
            dropdownOptions={dropdownOptionsToShow}
            fieldName={fieldName}
            flagForm={flagForm}
            flagFormValidator={flagFormValidator}
            isEditMode={isEditMode}
            rowId={rowId}
            setFieldName={setFieldName}
            updateFlagForm={updateFlagForm}
          />
        </div>
        <div className="row">
          <FlagFormRowButtons
            addRow={addRow}
            deleteRow={deleteRow}
            fieldName={fieldName}
            flagFormValidator={flagFormValidator}
            formLength={formLength}
            rowId={rowId}
            rowIndex={rowIndex}
          />
        </div>
      </div>
    </div>
  );
};

FlagFormRow.displayName = 'FlagFormRow';

FlagFormRow.propTypes = {
  addRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  flagFormValidator: PropTypes.instanceOf(FlagFormValidator).isRequired,
  formLength: PropTypes.number.isRequired,
  formRows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    fieldName: PropTypes.string,
  })).isRequired,
  flagForm: flagFormShape.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  rowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  rowIndex: PropTypes.number.isRequired,
  setFieldName: PropTypes.func.isRequired,
  updateFlagForm: PropTypes.func.isRequired,
};

FlagFormRow.defaultProps = {
  fieldName: '',
};

export default FlagFormRow;
