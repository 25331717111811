import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '../../lib/ReactIntlAdapter';
import MeetingMeta from './MeetingMeta';
import MeetingTags from '../meeting-tags/MeetingTags';
import tagShape from '../../types/tagShape';

const MeetingInfo = ({
  dayOfWeek,
  endTime,
  fellowship,
  id,
  language,
  localStartTime,
  startTime,
  tags,
}) => {
  const { locale } = useIntl();
  const hasForeignLanguage = language !== locale;
  const shouldShowTags = (tags && tags.length > 0)
    || hasForeignLanguage;

  return (
    <Fragment>
      <div className="row no-gutters">
        <div className="col-12">
          <MeetingMeta
            fellowship={fellowship}
            localStartTime={localStartTime}
            startTime={startTime}
            endTime={endTime}
            dayOfWeek={dayOfWeek}
          />
          {shouldShowTags && (
            <MeetingTags
              fellowship={fellowship}
              language={language}
              meetingId={id}
              tags={tags}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

MeetingInfo.displayName = 'MeetingInfo';

MeetingInfo.propTypes = {
  dayOfWeek: PropTypes.number,
  endTime: PropTypes.number,
  fellowship: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  language: PropTypes.string,
  localStartTime: PropTypes.number,
  startTime: PropTypes.number,
  tags: PropTypes.arrayOf(tagShape),
};

MeetingInfo.defaultProps = {
  dayOfWeek: '',
  endTime: '',
  fellowship: '',
  id: '',
  language: '',
  localStartTime: '',
  tags: [],
  startTime: '',
};

export default MeetingInfo;
