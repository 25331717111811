import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { createResource } from 'iguazu-rest';
import Select from 'react-select';
import { FormattedMessage, useIntl } from '../../lib/ReactIntlAdapter';
import DeadBrowser from '../../lib/svgArt/DeadBrowser.svg';
import Mail from '../../lib/svgArt/Mail.svg';
import flagFormConfigs from '../flag-form/FlagForm.configs.json';
import useToggle from '../../hooks/useToggle';

const ErrorExperience = ({
  animationClasses,
  meetingId,
  meetingPassword,
  resetModal,
  updateAnimationClasses,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [isPromptMode, togglePromptMode] = useToggle(true);

  const confirmErrorFeedbackSubmission = () => {
    updateAnimationClasses({ deadBrowser: 'fadeOut' });
    setTimeout(togglePromptMode, 250);
    setTimeout(() => updateAnimationClasses({ mail: 'fadeOutRight' }), 1500);
    setTimeout(resetModal, 2000);
  };

  const handleErrorFeedback = ({ value: supportKey }) => {
    const flagFormRows = [{ supportKey, value: 'true' }];

    dispatch(createResource({
      resource: 'support',
      id: {
        meetingId,
        flagFormRows,
      },
      opts: {
        body: JSON.stringify({
          meetingId,
          flagFormRows,
        }),
      },
    }))
      .then(() => confirmErrorFeedbackSubmission());
  };

  const baseCantJoinOptions = flagFormConfigs.dropdownOptions
    .filter((option) => option.includes('cantJoin') && !option.includes('wrongPassword'));

  const cantJoinOptions = meetingPassword
    ? ['cantJoin-wrongPassword', ...baseCantJoinOptions]
    : baseCantJoinOptions;

  const errorExperienceOptions = cantJoinOptions.map((optionEnum) => ({
    value: optionEnum,
    label: formatMessage({ id: `flagForm-inputPlaceholder-${optionEnum}` }),
  }));

  return isPromptMode ? (
    <Fragment>
      <div className="row">
        <div className="offset-3" />
        <div className="col-6">
          <img
            alt={formatMessage({ id: 'linkConfirmation-errorExperienceMessage' })}
            className={`p-5 w-100 mh-50 animated ${animationClasses.deadBrowser}`}
            src={DeadBrowser}
          />
          <p className="text-center">
            <FormattedMessage id="linkConfirmation-errorExperienceMessage" />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-5">
          <Select
            onChange={handleErrorFeedback}
            options={errorExperienceOptions}
            placeholder={formatMessage({ id: 'linkConfirmation-errorExperience-selectPlacholder' })}
          />
        </div>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="row">
        <div className="offset-3" />
        <div className="col-6">
          <img
            alt="no"
            className={`p-5 mw-100 mh-50 animated ${animationClasses.mail}`}
            src={Mail}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p className="text-center">
            <FormattedMessage id="linkConfirmation-errorFeedback" />
          </p>
        </div>
      </div>
    </Fragment>
  );
};

ErrorExperience.displayName = 'ErrorExperience';

ErrorExperience.propTypes = {
  animationClasses: PropTypes.shape({
    deadBrowser: PropTypes.string,
    mail: PropTypes.string,
  }).isRequired,
  meetingId: PropTypes.number.isRequired,
  meetingPassword: PropTypes.string,
  resetModal: PropTypes.func.isRequired,
  updateAnimationClasses: PropTypes.func.isRequired,
};

ErrorExperience.defaultProps = {
  meetingPassword: '',
};

export default ErrorExperience;
